import current from './modules/current';
import sidebar from './modules/sidebar';
import translations from './modules/translations';

export default {
  modules: {
    current,
    sidebar,
    translations
  }
}
