import { mapActions, mapGetters } from 'vuex';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { THEME } from '@/app/data/themes_constants';
import { differenceInSeconds } from 'date-fns';
import { VITE_ENV_DEVELOPMENT, VITE_ENV_REVIEW, VITE_ENV_STAGING } from '@/app/data/general_constants';

const profile_mixin = {

  props: {
    project_id: { type: String, default: null },
  },

  data() {
    return {
      initialized: false,
      refresh_app: false,
      poll_session_at: null,
      polled_session_at: null,
      fetch_client_at: null,
      fetch_profile_at: null,
      PROFILE_TYPES,
      VITE_ENV_DEVELOPMENT,
      VITE_ENV_STAGING,
      VITE_ENV_REVIEW,
      projects: {},
    };
  },

  watch: {
    sidebar_type: {
      handler() {
        const horizontal_padding = this.sidebar_type ? 520 : 20;
        window.Intercom('update', { horizontal_padding });
      },
    },
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_is_impersonation',
      'current_profile',
      'current_profile_true',
      'sidebar_type',
      'billings',
    ]),
    environment() { return import.meta.env.VITE_ENV; },
    billing_current_client() {
      return _.find(this.billings, ['attributes.client_id', this.current_client.id]);
    },

    theme_name() {
      return _.includes([PROFILE_TYPES.PARTNER, PROFILE_TYPES.SUPERVISOR], this.current_profile.type)
        ? THEME.DEFAULT
        : this.current_client.attributes.theme_name;
    },

    favicon_url() {
      return _.includes([PROFILE_TYPES.SUPERVISOR], this.current_profile.type)
        ? null
        : this.current_client.attributes.picture_favicon_url;
    },
  },

  created() {
    this.emitter.$on('fetchClient', this.fetchClient);
    this.emitter.$on('fetchProfile', this.fetchProfile);
    this.emitter.$on('invalidateSession', this.pollSession);
  },

  methods: {
    ...mapActions([
      'FETCH_CLIENT',
      'FETCH_PROJECTS',
      'FETCH_USER_PROFILE',
    ]),

    async initProfile() {
      await Promise.all([
        this.current_profile.type !== PROFILE_TYPES.SUPERVISOR ? this.fetchClient() : null,
        this.current_profile.type !== PROFILE_TYPES.SUPERVISOR ? this.fetchProjects() : null,
        this.current_profile.type === PROFILE_TYPES.CUSTOMER ? this.fetchUnits() : null,
      ]);
      this.emitter.$emit('initTitle');
      this.emitter.$emit('initTheme', this.theme_name);
      this.emitter.$emit('initFavicon', this.favicon_url);
      this.emitter.$emit('initIntercom');
      this.emitter.$emit('initRecording');
      this.initialized = true;
    },

    async fetchClient() {
      // locking mechanism to avoid concurrent fetches, with auto-release after 60s (on error)
      if (differenceInSeconds(this.fetch_client_at, new Date()) > 60) this.fetch_client_at = null;
      if (this.initialized && this.fetch_client_at) return;
      this.fetch_client_at = new Date();
      await this.FETCH_CLIENT({ id: this.current_profile.attributes.client_id });
      this.fetch_client_at = null;
    },

    async fetchProfile() {
      // locking mechanism to avoid concurrent fetches, with fail-safe auto-release after 60s
      if (differenceInSeconds(this.fetch_profile_at, new Date()) > 60) this.fetch_profile_at = null;
      if (this.initialized && this.fetch_profile_at) return;
      this.fetch_profile_at = new Date();
      await this.FETCH_USER_PROFILE({});
      this.fetch_profile_at = null;
    },

    async fetchProjects() {
      // vuex getters are not guaranteed to be initialized, thus saving via data variable instead
      const projects = await this.FETCH_PROJECTS();
      this.projects = _.keyBy(projects, 'id');
    },

    async pollSession() {
      // locking mechanism to avoid concurrent polling, with fail-safe auto-release after 60s
      if (differenceInSeconds(this.poll_session_at, new Date()) > 60) this.poll_session_at = null;
      // locking mechanism to rate limit actual polling to maximum once per 15 minutes (as demanded by auth0)
      if (differenceInSeconds(this.polled_session_at, new Date()) > 60 * 15) this.polled_session_at = null;
      if (this.initialized && (this.poll_session_at || this.polled_session_at)) return;
      this.poll_session_at = new Date();
      // enforces single logout: logout via one (sub)domain triggers logout on all domains (with cached token)
      await this.$auth.checkSession();
      this.polled_session_at = new Date();
      this.poll_session_at = null;
    },
  },
};

export default profile_mixin;
