import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  const locales = import.meta.glob('./locales/*.*.json', { eager: true, import: 'default' });
  const messages = {};
  Object.keys(locales).forEach((key) => {
    const matched = key.match(/\.([a-z]+)/);
    if (matched && matched.length > 1) {
      const locale = matched[1];

      if (!messages[locale]) {
        messages[locale] = {};
      }

      messages[locale] = { ...messages[locale], ...locales[key][locale] };
    }
  });
  return messages;
}

const DEFAULT_CURRENCY = {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
};

const numberFormats = {
  en: { currency: DEFAULT_CURRENCY },
  fr: { currency: DEFAULT_CURRENCY },
  nl: { currency: DEFAULT_CURRENCY },
};

function pluralRule(choice, choicesLength, orgRule) {
  // choicesLength is 4
  // this is reserved for notifications, combination of a single/multiple notifier(s) + single/multiple notifiable(s)
  // choice 0: single notifier, single notifiable
  // choice 1: single notifier, multiple notifiables
  // choice 2: multiple notifiers, single notifiable
  // choice 3: multiple notifiers, multiple notifiables
  if (choicesLength === 4) { return choice; }
  // choicesLength is 3: return default function
  return orgRule(choice, choicesLength);
}

const i18n = createI18n({
  locale: 'en',
  legacy: false,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  numberFormats,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
  pluralRules: {
    en: pluralRule,
    nl: pluralRule,
    fr: pluralRule,
  }
});

export default i18n;
