<template>
  <section>
    <a v-if="isEmptyHTML(html_text) " @click="$emit('onClick')">
      <slot/>
    </a>
    <div v-else>
      <div class="is-clipped"
           :style="is_expanded ? { 'max-height': '100%' } : { 'max-height' : `${height}px` }">
        <div
          ref="content"
          :class="contentClass"
          :style="!is_expanded && (content_height > height) ? { 'max-height': `${height}px` } : {}">
          <div class="content" data-test="text-content" v-html="html_text"/>
        </div>
      </div>
      <div v-if="content_height > height"
           class="is-italic" :class="{ 'mt-2': !one_liner }">
        <a :class="{ 'has-text-white': is_background_danger }" @click="is_expanded = !is_expanded">
          {{ $t(is_expanded ? 'editor_ziggu.actions.showLess' : 'editor_ziggu.actions.showMore') }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>

import { isEmptyHTML } from '@/app/util/editor/editor';

const ONE_LINER_HEIGHT = '20';

export default {
  name: 'Clamp',
  props: {
    html_text: { type: String, required: true },
    is_background_white: { type: Boolean },
    is_background_danger: { type: Boolean },
    max_height: { type: Number, default: 50 },
    one_liner: { type: Boolean },
  },
  data() {
    return {
      is_expanded: false,
      content_height: 0,
      isEmptyHTML,
    };
  },

  computed: {
    contentClass() {
      if (!this.is_expanded && (this.content_height > this.max_height) && !this.one_liner) {
        if (this.is_background_danger) return 'blur_danger_background';
        return this.is_background_white ? 'blur_white_background' : 'blur_grey_background';
      }
      return '';
    },

    height() {
      return this.one_liner ? ONE_LINER_HEIGHT : this.max_height;
    },
  },

  mounted() {
    this.$nextTick(() => {
      const client_height = _.get(this.$refs, 'content.clientHeight');
      this.content_height = client_height || this.content_height;
    });
  },
};

</script>

<style lang="scss">
  .blur_white_background {
    position: relative;
  }

  .blur_white_background:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, white 90%);
    width: 100%;
    height: 4em;
  }

  .blur_grey_background {
    position: relative;
  }

  .blur_grey_background:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgb(246, 246, 246) 90%);
    width: 100%;
    height: 4em;
  }

  .blur_danger_background {
    position: relative;
  }

  .blur_danger_background:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(241, 70, 104, 0) 0%, rgb(241, 70, 104) 90%);
    width: 100%;
    height: 4em;
  }
</style>
