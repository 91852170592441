<template>
  <aside id="sidenavbar_tenant"
         class="img-shadow"
         data-test="navbar-employee-desktop-client"
         style="overflow: hidden;">
    <div class="sidenavbar_up section">
      <div class="flex-center">
        <avatar
          :format="current_client.attributes.picture_logo_format"
          size="90"
          :src="current_client.attributes.picture_logo_url_s"
          :username="current_client.attributes.name"/>
      </div>
    </div>

    <div class="sidenavbar_down" style="overflow: hidden;">
      <ul style="overflow-y: auto;">
        <NavbarItem
          v-for="(item, idx) in NAVBAR_ITEMS_EMPLOYEES_CLIENT"
          :key="idx"
          :item="item"
          :params="item.route_params" />
      </ul>

      <div class="flex-center title is-5 section">
        <a href="https://ziggu.io" target="_blank">
          <img src="../../../../assets/images/ziggu_embossed_black.svg" style="opacity: 0.4;"></a>
      </div>
    </div>
  </aside>
</template>

<script>
import { NAVBAR_ITEMS_EMPLOYEES_CLIENT } from '@/app/data/navbar_constants';
import NavbarItem from '@/app/shared_components/navbar/NavbarItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NavbarDesktopClient',
  components: {
    NavbarItem,
  },

  data() {
    return {
      NAVBAR_ITEMS_EMPLOYEES_CLIENT,
    };
  },

  computed: {
    ...mapGetters(['current_client']),
  },
};
</script>
