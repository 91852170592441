// import used icons, see https://com/how-to-use/font-awesome-api#library-add
import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faAddressCard,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAnglesRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBath,
  faBed,
  faBell as fasBell,
  faBold,
  faBolt,
  faBook,
  faBug,
  faBuilding,
  faBullhorn,
  faCakeCandles,
  faCalculator,
  faCalendar as fasCalendar,
  faCamera,
  faCaretDown,
  faChartBar,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCloud,
  faCloudSun,
  faCodeBranch,
  faCircleXmark,
  faGear,
  faComment as fasComment,
  faComments,
  faCompass,
  faCreditCard,
  faDatabase,
  faDownload,
  faPenToSquare,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  faEuroSign,
  faExclamationCircle as fasExclamationCircle,
  faTriangleExclamation as fasTriangleExclamation,
  faFile,
  faFileLines as fasFileLines,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileSignature,
  faFileWord,
  faFireFlameSimple,
  faFolder,
  faForward,
  faHandshake,
  faHardHat,
  faHouse,
  faImages,
  faInbox,
  faInfo,
  faItalic,
  faKey,
  faKeyboard,
  faLaughBeam,
  faLightbulb,
  faLink,
  faList,
  faListOl,
  faLock,
  faLocationDot,
  faMinus,
  faCircleMinus,
  faMousePointer,
  faPalette,
  faPaperPlane,
  faPencilRuler,
  faPercentage,
  faPhone,
  faPlus,
  faCirclePlus,
  faPoll,
  faCircleQuestion as fasCircleQuestion,
  faReceipt,
  faRotateRight,
  faRuler,
  faSave,
  faMagnifyingGlass,
  faShare,
  faRightFromBracket,
  faSliders,
  faSort,
  faArrowDownWideShort,
  faArrowUpShortWide,
  faSpinner,
  faStickyNote,
  faStrikethrough,
  faRotate,
  faTable,
  faTag,
  faTh,
  faXmark,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faRotateLeft,
  faUnlink,
  faUserPlus,
  faUpload as fasUpload,
  faUser,
  faUserLarge,
  faUserGroup,
  faUserLock,
  faUnlock,
  faUsers,
  faUserSecret,
  faUserTie,
  faVideo,
  faWarehouse,
  faWater,
  faGrip,
} from '@fortawesome/free-solid-svg-icons';

import {
  faAlarmClock,
  faArrowUpRightFromSquare,
  faCircleInfo as fasCircleInfo,
  faCircleCheck,
  faColumns3,
  faDiagramNested,
  faExpandWide,
  faHandshakeAlt,
  faGridHorizontal,
  faLayerPlus as fasLayerPlus,
  faLocationCheck,
  faPaperclipVertical,
  faPartyHorn,
  faPersonDolly,
  faRocketLaunch,
  faShuffle,
  faStars,
  faUpRightAndDownLeftFromCenter,
  faUserHelmetSafety,
  faHousePersonLeave,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBell as farBell,
  faBellSlash,
  faCalendar as farCalendar,
  faCheckCircle,
  faSquareCheck,
  faClock,
  faComment as farComment,
  faCopy,
  faEye,
  faEyeSlash,
  faFileLines as farFileLines,
  faFrown,
  faNewspaper,
  faCircleQuestion as farCircleQuestion,
  faRegistered,
  faSave as farSave,
  faThumbsUp,
} from '@fortawesome/free-regular-svg-icons';

import {
  faBee,
  faCircleInfo as farCircleInfo,
  faClipboardList,
  faPersonDigging,
  faDolly,
  faHeartCrack,
  faExclamationCircle as farExclamationCircle,
  faTriangleExclamation as farTriangleExclamation,
  faExternalLink,
  faGarage,
  faHouseCircleCheck,
  faHighlighter,
  faHouseChimneyHeart as farHouseChimneyHeart,
  faPen,
  faReceipt as farReceipt,
  faSignature,
  faStore,
  faTimesCircle as farTimesCircle,
  faUpload as farUpload,
  faEnvelopeCircleCheck as farEnvelopeCircleCheck,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faGoogleDrive,
  faHubspot,
  faIntercom,
  faSalesforce,
} from '@fortawesome/free-brands-svg-icons';

import {
  faSadTear,
  faCalendarCheck,
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faReceipt as falReceipt,
  faFilePlus,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faAddressCard,
  faAlarmClock,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAnglesRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowDownWideShort,
  faArrowUpShortWide,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBath,
  faBed,
  faBee,
  faBellSlash,
  faBold,
  faBolt,
  faBook,
  faBug,
  faBuilding,
  faBullhorn,
  faCakeCandles,
  faCalculator,
  faCalendarCheck,
  faCamera,
  faCaretDown,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faSquareCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faClipboardList,
  faClock,
  faCloud,
  faCloudSun,
  faCodeBranch,
  faGear,
  faColumns3,
  faComments,
  faCompass,
  faCopy,
  faCreditCard,
  faDatabase,
  faDiagramNested,
  faDolly,
  faDownload,
  faPenToSquare,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  farEnvelopeCircleCheck,
  faEuroSign,
  faExpandWide,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileExcel,
  faFilePdf,
  faFilePlus,
  faFilePowerpoint,
  faFileSignature,
  faFileWord,
  faFireFlameSimple,
  faFolder,
  faForward,
  faFrown,
  faGarage,
  faGoogleDrive,
  faGrip,
  faHandshake,
  faHandshakeAlt,
  faHardHat,
  faHeartCrack,
  faHighlighter,
  faHouse,
  faHouseCircleCheck,
  faHubspot,
  faImages,
  faInbox,
  faInfo,
  faIntercom,
  faItalic,
  faKey,
  faKeyboard,
  faLaughBeam,
  faLightbulb,
  faLink,
  faList,
  faListOl,
  faLocationCheck,
  faLocationDot,
  faLock,
  falReceipt,
  faMinus,
  faCircleMinus,
  faMousePointer,
  faNewspaper,
  faPalette,
  faPaperclipVertical,
  faPaperPlane,
  faPartyHorn,
  faPen,
  faPencilRuler,
  faPercentage,
  faPersonDigging,
  faPersonDolly,
  faHousePersonLeave,
  faPhone,
  faPlus,
  faPoll,
  farBell,
  farCalendar,
  farComment,
  faReceipt,
  faRotateRight,
  faRegistered,
  farExclamationCircle,
  farTriangleExclamation,
  farFileLines,
  farHouseChimneyHeart,
  farCircleInfo,
  faRocketLaunch,
  farCircleQuestion,
  farReceipt,
  farSave,
  farTimesCircle,
  faRuler,
  farUpload,
  faSadTear,
  faSalesforce,
  faSave,
  fasBell,
  fasCalendar,
  fasComment,
  faMagnifyingGlass,
  fasExclamationCircle,
  fasTriangleExclamation,
  fasFileLines,
  faShare,
  faShuffle,
  faSignature,
  faRightFromBracket,
  fasCircleInfo,
  fasLayerPlus,
  faSliders,
  faSort,
  faArrowDownWideShort,
  faSpinner,
  fasCircleQuestion,
  faStickyNote,
  faStrikethrough,
  fasUpload,
  faStars,
  faRotate,
  faStore,
  faTable,
  faTag,
  faTh,
  faGridHorizontal,
  faThumbsUp,
  faXmark,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faRotateLeft,
  faUnlink,
  faUnlock,
  faUpRightAndDownLeftFromCenter,
  faArrowUpRightFromSquare,
  faUser,
  faUserLarge,
  faUserGroup,
  faUserHelmetSafety,
  faUserLock,
  faUserPlus,
  faUsers,
  faUserSecret,
  faUserTie,
  faVideo,
  faWarehouse,
  faWater,
);

dom.watch();
