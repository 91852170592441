import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesIssueList = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
    path: 'issues',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListUnitSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_DOCUMENTS,
    path: 'documents',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListDocumentsSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_TICKETS,
    path: 'tickets',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListTicketsSubpage.vue'),
    props: true,
  },
];

export default routesIssueList;
