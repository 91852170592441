export const local_storage = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  get(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : {};
  },
};

export const LOCALSTORAGE_OPTIONS = Object.freeze({
  SIDEBAR: 'sidebar',
  DECISION_TYPES_MATRIX: 'decision_types_matrix',
  CLIENTS_TABLE: 'clients_table',
  DECISIONS_TABLE: 'decisions_table',
  MESSAGE_TRANSFER_INFO: 'message_transfer_info',
  UNIT_DECISIONS_TABLE: 'unit_decisions_table',
  NOTIFICATIONS_LAST_OPENED: 'notifications_last_opened',
  TICKETS: 'tickets',
  TICKETS_DRAFTS: 'tickets_drafts',
});

const LOCALSTORAGE_STATIC_KEYS = new Set([
  'customer_notifications_last_opened',
  'employee_decisions_table',
  'employee_decision_types_matrix',
  'employee_notifications_last_opened',
  'employee_sidebar',
  'employee_tickets',
  'employee_message_transfer_info',
  'employee_unit_decisions_table',
  'partner_notifications_last_opened',
  'partner_sidebar',
  'supervisor_clients_table',
]);

const LOCALSTORAGE_DYNAMIC_KEYS = [
  'employee_decisions_table_', // per decision_type_id, ex. employee_decisions_table_135
  'employee_tickets_drafts_', // per ticket, ex. employee_tickets_drafts_135, to save drafts
  'employee_unit_decisions_table_',
  'customer_tickets_drafts_',
  'partner_tickets_drafts_',
];

export function localStorageKey(profile, element, dynamic = false) {
  const { type, id } = profile;
  if (!type || !id) return null;
  const key = `${type.toLowerCase()}_${element}`;

  let local_storage_key;
  if (dynamic) {
    const has_key = _.reduce(LOCALSTORAGE_DYNAMIC_KEYS, (res, d_key) => res || key.includes(d_key), false);
    local_storage_key = has_key ? `${key}_${id}` : null;
  } else {
    local_storage_key = LOCALSTORAGE_STATIC_KEYS.has(key) ? `${key}_${id}` : null;
  }

  return local_storage_key;
}
