<template>
  <aside v-if="current_project" id="sidenavbar_project"
         data-test="navbar-employee-desktop-project"
         style="overflow: hidden;">
    <div class="sidenavbar_up section">
      <div class="flex-center">
        <div>
          <avatar
            size="90"
            :src="avatar"
            :username="current_project.attributes.name"/>
        </div>
      </div>
      <br>
      <p class="title is-4 ziggu-navbar-item" style="margin: 20px 0px;">
        {{ current_project.attributes.name }}
      </p>
      <div class="is-flex flex-center" style="flex-direction: row;">
        <router-link data-test="all-projects-button" :to="{ name: ROUTES_EMPLOYEES.CLIENT_PROJECTS}" >
          <div class="button is-rounded is-white">
            <div class="icon">
              <i class="fas fa-arrow-left"/>
            </div>
            <div>
              {{ $t('project.labels.projects') }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="sidenavbar_down" style="overflow: hidden;">
      <ul style="overflow-y: auto;">
        <NavbarItem
          v-for="(item, idx) in routesActive"
          :key="idx"
          :item="item"
          :params="{
            project_id: current_project.id,
            level_type: current_project.type,
            level_id: current_project.id
          }"/>
      </ul>
      <div class="flex-center title is-5 has-text-white section">
        <a href="https://ziggu.io" target="_blank">
          <img src="../../../../assets/images/ziggu_embossed_black.svg" style="opacity: 0.4;">
        </a>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import { NAVBAR_ITEMS_EMPLOYEES_PROJECT } from '@/app/data/navbar_constants';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import NavbarItem from '@/app/shared_components/navbar/NavbarItem.vue';

export default {
  name: 'NavbarDesktopProject',
  components: {
    NavbarItem,
  },

  data() {
    return {
      NAVBAR_ITEMS_EMPLOYEES_PROJECT,
      ROUTES_EMPLOYEES,
    };
  },

  computed: {
    ...mapGetters(['current_client', 'current_project']),
    routesActive() { return _.filter(NAVBAR_ITEMS_EMPLOYEES_PROJECT, this.isRouteActive); },
    avatar() {
      const { dedicated, picture_logo_url_s, picture_login_url_s } = this.current_project.attributes;
      return dedicated ? (picture_logo_url_s || picture_login_url_s) : picture_login_url_s;
    },
  },

  methods: {
    isRouteActive(route) {
      let access = true;
      if (route.ft_client_flag) {
        const flags = route.ft_client_flag.split(',');
        flags.forEach((flag) => {
          if (!this.current_client.attributes[flag]) {
            access = false;
          }
        });
        if (!route.feature_toggle) return access;
      }

      return route.feature_toggle ? (access && this.current_project.attributes[route.feature_toggle]) : access;
    },
  },
};
</script>
