<template>
  <div>
    <b-autocomplete
      ref="autocomplete"
      v-model="unit_name"
      :data="filteredUnits"
      data-test="select-unit-input"
      :disabled="isDisabled"
      field="attributes.name"
      open-on-focus
      @focus="$emit('focus')"
      @select="selectUnit"
      @update:modelValue="searchUnit">
      <template #empty>
        <div data-test="empty-result">
          <div v-if="is_searching">
            {{ $t('select.actions.noResults') }} <span v-if="unit_name">"{{ unit_name }}"</span>
          </div>
          <div v-else>
            {{ $t('select.actions.startTyping') }}
          </div>
        </div>
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'SelectUnit',
  props: {
    active: { type: Object, default: null },
    project_id: { type: String, default: null },
    project_required: { type: Boolean },
    units: { type: Object, default: null },
  },

  emits: ['focus', 'on-search', 'change-select'],

  data() {
    return {
      unit_name: this.active ? this.active.attributes.name : '',
      is_searching: false,
    };
  },

  computed: {
    isDisabled() {
      return this.project_required && _.isNil(this.project_id);
    },
    filteredUnits() {
      return _.filter(this.units,
        (p) => p.attributes.name.toLowerCase().indexOf(this.unit_name.toLowerCase()) >= 0);
    },
  },

  watch: {
    active(newVal) {
      this.unit_name = _.get(newVal, 'attributes.name', '');
    },
  },
  methods: {
    searchUnit(query) {
      if (query.length >= 3) {
        this.is_searching = true;
        if (!this.active) {
          this.$emit('onSearch', query);
        }
      }
    },

    selectUnit(unit) {
      this.$emit('changeSelect', unit);
    },
  },
};
</script>
