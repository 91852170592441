<template>
  <nav v-if="!$_.isNil(current_project)" class="navbar-sm-project navbar"
       data-test="navbar-customer-tablet-sidenavbar">
    <div class="navbar-brand level is-mobile" style="margin: auto 4px; height: 100%;">
      <div class="level-left">
        <div class="navbar-burger ziggu-navbar-item" :class="{ 'is-active': showNav }"
             style="margin-right: auto; margin-left: 0;"
             @click="showNav = !showNav">
          <span/>
          <span/>
          <span/>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div v-if="$_.values(projects).length > 1" class="select">
            <b-field>
              <b-select
                :modelValue="current_project.id"
                rounded
                @update:modelValue="p_id => initializeProject(p_id)">
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id">
                  {{ project.attributes.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div v-else class="title is-5 ziggu-navbar-item">
            {{ current_project.attributes.name }}
          </div>
        </div>
        <NotificationSidebar/>
        <ProfileDropdown/>
      </div>
    </div>
    <div class="navbar-menu navbar-color-project" :class="{ 'is-active': showNav }">
      <div class="navbar-end">
        <router-link v-for="(route, idx) in routesActive" :key="idx"
                     :data-test="route.data_test"
                     :to="{ name: route.name, params: { project_id: current_project.id } }">
          <div class="navbar-item ziggu-navbar-item is-size-6 level is-mobile py-3 px-4"
               :class="{ 'has-background-primary has-text-weight-semibold active':
                 $route.name.startsWith(route.prefix) }"
               @click="showNav = !showNav">
            <div class="level-left">
              <div class="level-item">
                <div class="icon mr-2">
                  <FontAwesomeIcon
                    :icon="route.icon"/>
                </div>
              </div>
              <p class="level-item">
                {{ $t(route.title) }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationSidebar from '@/app/shared_components/navbar/notifications/TheNotificationsSidebar.vue';
import ProfileDropdown from '@/app/shared_components/navbar/TheProfileDropdown.vue';
import { NAVBAR_ITEMS_CUSTOMER } from '@/app/data/navbar_constants';
import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';

export default {
  name: 'NavbarTablet',
  components: { NotificationSidebar, ProfileDropdown },

  data() {
    return {
      NAVBAR_ITEMS_CUSTOMER,
      showNav: false,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_project',
      'projects',
    ]),
    routesActive() { return _.filter(NAVBAR_ITEMS_CUSTOMER, this.isRouteActive); },
  },

  methods: {
    initializeProject(project_id) {
      this.$router.push({
        name: ROUTES_CUSTOMERS.DASHBOARD,
        params: { project_id, client_id: this.current_client.id },
      });
    },

    isRouteActive(route) {
      return route.feature_toggle ? this.current_project.attributes[route.feature_toggle] : true;
    },
  },
};
</script>
