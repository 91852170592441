<template>
  <b-dropdown data-test="dropdown-general-user" :mobile-modal="false" position="is-bottom-left"
              style="z-index: 40;">
    <template #trigger>
      <div v-if="current_profile" class="topbar-item" style="cursor: pointer">
        <Avatar
          size="30"
          :src="current_profile.attributes.avatar_url"
          style="padding-right: 10px;"
          :username="current_profile.attributes.name"/>
        <span v-if="mq.isDesktop" class="title is-6 has-text-grey">
          {{ $truncate(current_profile.attributes.first_name, 50) }}
        </span>
      </div>
    </template>
    <a
      v-if="has_multiple_profiles && !mq.isDesktop"
      class="dropdown-item"
      data-test="select-company"
      @click="selectTenant">
      <div>
        <FontAwesomeIcon icon="building" pack="fas" size="lg"/>&nbsp; &nbsp;  {{ $t('navbar.topnavbar.selectTeam') }}
      </div>
    </a>
    <router-link
      v-if="current_profile.type !== PROFILE_TYPES.SUPERVISOR"
      class="dropdown-item"
      data-test="profile"
      :to="{ name: routeProfileName, params: routeProfileParams }">
      <div>
        <i class="icon is-small fas fa-gear"/>&nbsp; &nbsp; {{ $t('navbar.topnavbar.profile') }}
      </div>
    </router-link>
    <div v-if="current_profile.type === PROFILE_TYPES.EMPLOYEE">
      <router-link
        class="dropdown-item"
        data-test="admin"
        :to="{ name: ROUTES_EMPLOYEES.CLIENT_COMPANY }">
        <div>
          <i class="icon is-small fas fa-user-tie"/>&nbsp; &nbsp; {{ $t('navbar.topnavbar.company') }}
        </div>
      </router-link>
      <router-link
        v-if="current_profile.attributes.role !== Employee.ROLE_MAINTAINER"
        class="dropdown-item"
        data-test="team"
        :to="{ name: ROUTES_EMPLOYEES.SETTINGS_TEAM }">
        <div class="is-flex is-align-items-center">
          <FontAwesomeIcon class="mr-4" icon="user-group"/>
          {{ $t('navbar.topnavbar.team') }}
        </div>
      </router-link>
    </div>
    <a class="dropdown-item"
       data-test="logout"
       @click="emitter.$emit('logout')">
      <div>
        <i class="icon is-small fas fa-right-from-bracket"/>&nbsp; &nbsp; {{ $t('navbar.topnavbar.logout') }}
      </div>
    </a>
    <a v-if="current_is_impersonation && current_profile_true.type === PROFILE_TYPES.SUPERVISOR"
       class="dropdown-item"
       @click="emitter.$emit('stopImpersonate')">
      <i class="icon is-small fas fa-user-secret"/>&nbsp; &nbsp; {{ $t('contact.actions.impersonateStop') }}
    </a>
  </b-dropdown>
</template>

<script>
import { PROFILE_TYPES, Employee } from '@/app/data/model_constants';
import { ROUTES_EMPLOYEES, ROUTES_CUSTOMERS, ROUTES_PARTNERS } from '@/app/data/route_constants';
import SelectTenant from '@/app/shared_components/SelectTenant.vue';
import { mapGetters } from 'vuex';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'ProfileDropdown',

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      PROFILE_TYPES,
      ROUTES_EMPLOYEES,
      ROUTES_CUSTOMERS,
      ROUTES_PARTNERS,
      Employee,
    };
  },

  computed: {
    ...mapGetters([
      'current_is_impersonation',
      'current_profile',
      'current_profile_true',
      'current_project',
    ]),

    routeProfileName() {
      switch (this.current_profile.type) {
      case PROFILE_TYPES.CUSTOMER:
        return ROUTES_CUSTOMERS.SETTINGS_PROFILE;
      case PROFILE_TYPES.EMPLOYEE:
        return ROUTES_EMPLOYEES.SETTINGS_PROFILE;
      case PROFILE_TYPES.PARTNER:
        return ROUTES_PARTNERS.SETTINGS_PROFILE;
      default:
        return '';
      }
    },

    has_multiple_profiles() {
      if (this.current_is_impersonation && this.current_profile_true.type === PROFILE_TYPES.EMPLOYEE) return false;
      return this.current_profile.attributes.profiles_count > 1;
    },

    routeProfileParams() {
      // route for customers is namespaced with project_id
      return this.current_profile.type === PROFILE_TYPES.CUSTOMER
        ? { project_id: _.get(this.current_project, 'id', 0) } // dummy value prevents vue router warning
        : {};
    },
  },

  methods: {
    selectTenant() {
      this.$buefy.modal.open({

        component: SelectTenant,
      });
    },
  },
};
</script>
