<template>
  <nav class="navbar"
       :class="[!$_.isNil(current_project) ? 'navbar-sm-project' : 'navbar-sm-tenant']"
       data-test="navbar-employee-tablet">
    <div class="navbar-brand level is-mobile" style="margin: auto 5px; height: 100%;">
      <div class="level-left">
        <div class="navbar-burger ziggu-navbar-item level-item"
             :class="{ 'is-active': show_navbar }"
             @click="show_navbar = !show_navbar">
          <span/>
          <span/>
          <span/>
        </div>
      </div>
      <div class="level-right">
        <HelpSidebar/>
        <NotificationSidebar/>
        <ProfileDropdown/>
      </div>
    </div>
    <div
      class="navbar-menu"
      :class="[
        !$_.isNil(current_project) ?
          'navbar-color-project' : 'navbar-color-tenant', show_navbar ? 'is-active' :
          ''
      ]">
      <div class="navbar-end">
        <router-link v-for="route in routes" :key="route.name"
                     :data-test="route.data_test"
                     :to="{ name: route.name, params }">
          <div class="navbar-item ziggu-navbar-item is-size-6 level is-mobile py-3 px-4"
               :class="{ 'has-background-primary has-text-weight-semibold active':
                 $route.name.startsWith(route.prefix) }"
               @click="show_navbar = !show_navbar">
            <div class="level-left">
              <div class="level-item">
                <div class="icon mr-2">
                  <FontAwesomeIcon
                    :icon="route.icon"/>
                </div>
              </div>
              <p class="level-item">
                {{ $t(route.title) }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  NAVBAR_ITEMS_PARTNERS_CLIENT,
  NAVBAR_TABLET_ITEMS_PARTNERS_PROJECT,
  NAVBAR_ITEMS_PARTNERS_PROJECT_ACTIVE_ROLE_NEEDED,
} from '@/app/data/navbar_constants';
import NotificationSidebar from '@/app/shared_components/navbar/notifications/TheNotificationsSidebar.vue';
import HelpSidebar from '@/app/shared_components/navbar/TheHelpSidebar.vue';
import ProfileDropdown from '@/app/shared_components/navbar/TheProfileDropdown.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NavbarTablet',
  components: {
    HelpSidebar,
    NotificationSidebar,
    ProfileDropdown,
  },

  data() {
    return {
      show_navbar: false,
    };
  },

  computed: {
    ...mapGetters(['current_project', 'current_profile']),
    routes() {
      return !_.isNil(this.current_project)
        ? _.filter(NAVBAR_TABLET_ITEMS_PARTNERS_PROJECT, this.isRouteActive)
        : NAVBAR_ITEMS_PARTNERS_CLIENT;
    },
    params() {
      if (_.isNil(this.current_project)) return {};

      return {
        project_id: this.current_project.id,
        level_type: this.current_project.type,
        level_id: this.current_project.id,
      };
    },
  },

  watch: {
    $route() {
      if (this.show_navbar) { this.show_navbar = false; }
    },
  },

  methods: {
    isRouteActive(route) {
      if (route.feature_toggle
        && !_.get(this.current_project, `attributes.${route.feature_toggle}`, false)) return false;
      if (NAVBAR_ITEMS_PARTNERS_PROJECT_ACTIVE_ROLE_NEEDED.includes(route.name)) {
        return this.current_profile.attributes.active_role_project_ids.includes(parseInt(this.current_project.id, 10));
      }

      return true;
    },
  },
};
</script>
