<template>
  <div v-if="!initialized">
    <b-loading :model-value="true"/>
  </div>
  <Modal v-else
         :has_parent="false"
         :is_show_footer="false"
         :is_show_header="false"
         :is_small="false"
         @actionCancel="$parent.$parent.close"
         @actionPrimary="$parent.$parent.close">
    <template #content>
      <div>
        <header class="media mb-5">
          <div class="media-left" style="flex-grow: 1;">
            <div class="content">
              <div class="title is-5">
                {{ $t('navbar.topnavbar.selectTeam') }}
              </div>
              <div class="subtitle is-6 has-text-grey-light">
                {{ email_address }}
              </div>
            </div>
          </div>
          <div class="media-right">
            <img
              alt="logo-ziggu" src="../../assets/images/ziggu_embossed_black.svg"
              style="opacity: 0.4;">
          </div>
        </header>

        <a v-for="tenant in current_user_tenants"
           :key="`${tenant.id}-${tenant.type}`"
           :href="zigguProfileTenantURL(getProfile(tenant), tenant)">
          <div
            class="media my-4 p-2 hover-select"
            :class="{ 'hover-selected': subdomain === tenant.attributes.subdomain }">
            <div class="media-left">
              <figure class="image is-16x16" style="margin-top: 0.25rem;">
                <img
                  alt="favicon-engage"
                  :src="tenant.attributes.picture_favicon_url">
              </figure>
            </div>
            <div class="media-content has-text-weight-medium">
              {{ tenant.attributes.name }}
              <div class="has-text-grey-light is-italic">
                {{ getHostname(zigguProfileTenantURL(getProfile(tenant), tenant)) }}
              </div>
            </div>
          </div>
        </a>

        <footer v-if="!$_.isEmpty(current_user)" class="is-flex mt-4" style="justify-content: flex-end;">
          <ButtonTertiary
            action="sign_out"
            @onClick="emitter.$emit('logout')">
            {{ $t('navbar.topnavbar.logout') }}
          </ButtonTertiary>
        </footer>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/app/shared_components/modals/TheModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { RECORD_TYPE, PROFILE_TYPES } from '@/app/data/model_constants';
import { LOGIN_PAGE_NOTIFICATIONS, VITE_ENV_REVIEW } from '@/app/data/general_constants';
import { ZIGGU_PARENT_URL, zigguProfileTenantURL } from '@/app/util/urls';

export default {
  name: 'SelectTenant',

  components: {
    Modal,
  },

  data() {
    return {
      initialized: false,
      PROFILE_TYPES,
      zigguProfileTenantURL,
    };
  },

  computed: {
    ...mapGetters([
      'current_is_impersonation',
      'current_profile',
      'current_user',
      'current_user_customers',
      'current_user_employees',
      'current_user_partners',
      'current_user_supervisors',
      'current_user_clients',
      'current_user_projects',
      'current_user_tenants',
    ]),

    email_address() {
      return this.is_parent_domain
        ? this.current_user.attributes.email_address
        : this.current_profile.attributes.email_address;
    },

    is_parent_domain() { return ZIGGU_PARENT_URL === window.location.origin; },

    origin() { return window.location.origin; },

    subdomain() {
      let _subdomain = null;
      const parts = window.location.host.split('.');
      if (import.meta.env.VITE_ENV === VITE_ENV_REVIEW) {
        if (parts.length > 3) [_subdomain] = parts;
      } else if (parts.length === 3) {
        [_subdomain] = parts;
      }
      return _subdomain;
    },

    profiles() {
      const profiles = [
        Object.values(this.current_user_supervisors),
        Object.values(this.current_user_employees),
        Object.values(this.current_user_customers),
        Object.values(this.current_user_partners),
      ];
      return [].concat(...profiles);
    },
  },

  async created() {
    await this.fetchProfiles();
  },

  methods: {
    ...mapActions([
      'FETCH_USER_PROFILES',
    ]),

    urlProfile(profile) {
      const url = this.subdomain
        ? this.origin.replace(/^https:\/\/[^.]*\./, `https://${profile.attributes.subdomain}.`)
        : this.origin.replace('https://', `https://${profile.attributes.subdomain}.`);
      // append meta query params on impersonation for non-supervisor profiles
      if (this.current_is_impersonation && profile.type !== PROFILE_TYPES.SUPERVISOR) {
        return `${url}?_profile_type=${profile.type}&_profile_id=${profile.id}`;
      }
      return url;
    },

    urlTenant(tenant) {
      const url = this.subdomain
        ? this.origin.replace(/^https:\/\/[^.]*\./, `https://${tenant.attributes.subdomain}.`)
        : this.origin.replace('https://', `https://${tenant.attributes.subdomain}.`);
      // append meta query params on impersonation for non-supervisor profiles
      if (this.current_is_impersonation && tenant.attributes.subdomain !== 'supervisor') {
        const profile = this.getProfile(tenant);
        return `${url}?_profile_type=${profile.type}&_profile_id=${profile.id}`;
      }
      return url;
    },

    getProfile(tenant) {
      if (tenant.attributes.name === 'supervisor') return _.values(this.current_user_supervisors)[0];

      const client_id = tenant.attributes.tenantable_type === RECORD_TYPE.PROJECT
        ? this.current_user_projects[tenant.attributes.tenantable_id].attributes.client_id
        : tenant.attributes.tenantable_id;
      return _.find(this.profiles, ['attributes.client_id', client_id]);
    },

    getHostname(url) {
      return (new URL(url)).hostname;
    },

    async fetchProfiles() {
      await this.FETCH_USER_PROFILES({});
      const tenants_count = _.size(this.current_user_tenants);
      if (tenants_count === 1 && this.is_parent_domain) {
        // skip profile switcher on TLD for single-profile users, redirect straight to profile's tenant
        window.location.href = this.urlProfile(_.values(this.current_user_tenants)[0]);
      } else if (tenants_count === 0) {
        const url = new URL(window.location.origin);
        url.searchParams.append('ziggu_notification', LOGIN_PAGE_NOTIFICATIONS.ERROR_NO_ACCESS);
        const returnTo = url.toString();
        this.$auth.logout({ returnTo });
      } else {
        this.initialized = true;
      }
    },
  },
};
</script>
