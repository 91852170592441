<template>
  <div
    class="mx-4">
    <section
      :class="`level is-flex mb-4 ${(mq.isMobile || mq.isTablet) ? 'is-mobile' : ''}`"
      style="align-items: center;">
      <div class="level-left">
        <ButtonTertiary
          v-if="current_profile.type !== PROFILE_TYPES.CUSTOMER"
          action="filter"
          class="level-item button has-text-weight-semibold has-text-grey"
          data-test="notifications-filter"
          @onClick="toggle_filter = !toggle_filter">
          <span>{{ $t('notification.labels.filter') }}</span>
          <span class="ml-2">
            <FontAwesomeIcon :icon="toggle_filter ? 'angle-up' : 'angle-down'"/>
          </span>
        </ButtonTertiary>
        <BDropdown position="is-bottom-right">
          <template #trigger>
            <b-button
              class="level-item button has-text-weight-semibold has-text-grey"
              data-test="notification-sorting-dropdown">
              <span class="mr-2" style="margin-left: -0.25rem;">
                <FontAwesomeIcon icon="arrow-down-wide-short"/>
              </span>
              <span>{{ $t('notification.labels.sort') }}:
                {{ $t(`notification.labels.${sorting_label}`) }}
              </span>
              <span class="ml-2">
                <FontAwesomeIcon icon="angle-down"/>
              </span>
            </b-button>
          </template>
          <BDropdownItem data-test="sort-updated-at-desc" @click="$emit('setSortOrder',['updated_at desc'])">
            {{ $t('notification.labels.updated_at_desc') }}
          </BDropdownItem>

          <BDropdownItem data-test="sort-updated-at-asc" @click="$emit('setSortOrder', ['updated_at asc'])">
            {{ $t('notification.labels.updated_at_asc') }}
          </BDropdownItem>

          <BDropdownItem
            data-test="sort-level-name-asc"
            @click="$emit('setSortOrder', ['notification_level_type desc', 'notification_level_name asc'])">
            {{ $t('notification.labels.notification_level_name_asc') }}
          </BDropdownItem>

          <BDropdownItem
            data-test="sort-level-name-desc"
            @click="$emit('setSortOrder', ['notification_level_type desc', 'notification_level_name desc'])">
            {{ $t('notification.labels.notification_level_name_desc') }}
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="level-right">
        <div class="level-item">
          <VPopper>
            <template #tip>
              {{ $t('notification.actions.markAllAsRead') }}
            </template>
            <template #content>
              <a @click="$emit('openNotificationSubgroupsAll')">
                <FontAwesomeIcon class="mark-notification-read" :icon="['far', 'eye']" size="lg" />
              </a>
            </template>
          </VPopper>
        </div>
      </div>
    </section>

    <section
      v-if="current_profile.type === PROFILE_TYPES.EMPLOYEE"
      class="is-flex mb-4" style="align-items: center;">
      <div
        style="flex-grow: 1; display: flex; align-items: center;">
        <b-taglist>
          <FilterTag
            v-if="!q_project_id"
            :closable="false"
            data-test="all-projects-tag-filter"
            size="is-medium">
            {{ $t('notification.labels.all') }}
          </FilterTag>
          <FilterTag
            v-if="q_project_id && project_selected"
            data-test="project-tag-filter"
            size="is-medium"
            @close="q_project_id = null">
            {{ $truncate(project_selected.attributes.name, 20) }}
          </FilterTag>
          <FilterTag
            v-if="q_unit_id && unit_selected"
            data-test="unit-tag-filter"
            size="is-medium"
            @close="q_unit_id = null">
            {{ $truncate(unit_selected.attributes.name, 20) }}
          </FilterTag>
          <FilterTag
            v-if="!q_decision_type_id"
            :closable="false"
            data-test="all-decisions-tag-filter"
            size="is-medium">
            {{ $t('notification.labels.allDecisions') }}
          </FilterTag>
          <FilterTag
            v-if="q_decision_type_id && decision_type_selected"
            data-test="decisions-tag-filter"
            size="is-medium"
            @close="q_decision_type_id = null">
            {{ $truncate(decision_type_selected.attributes.name, 20) }}
          </FilterTag>
          <FilterTag
            v-if="q_is_unread_only"
            data-test="unread-only-tag-filter"
            size="is-medium"
            @close="q_is_unread_only = false">
            {{ $t('notification.labels.unreadOnly') }}
          </FilterTag>
        </b-taglist>
      </div>
    </section>

    <section>
      <div
        v-if="toggle_filter"
        class="is-relative mb-4"
        :focusable="false">
        <b-field :label="$t('notification.labels.status')">
          <b-field>
            <div class="buttons">
              <ToggleToken
                data-test="show-all-notifications"
                :is_toggled="!q_is_unread_only"
                @onClick="q_is_unread_only = false">
                {{ $t('notification.labels.allStatuses') }}
              </ToggleToken>
              <ToggleToken
                color="is-primary"
                data-test="show-unopen-notifications"
                :is_toggled="q_is_unread_only"
                @onClick="q_is_unread_only = !q_is_unread_only">
                {{ $t('notification.labels.unreadOnly') }}
              </ToggleToken>
            </div>
          </b-field>
        </b-field>

        <b-field v-if="current_profile.type === PROFILE_TYPES.EMPLOYEE" grouped>
          <b-field expanded :label="$t('ticket.labels.project')">
            <SelectProject
              :active="project_selected"
              data-test="select-project"
              :projects="projects"
              @changeSelect="(p) => q_project_id = $_.get(p, 'id')"
              @onSearch="autocompleteProjects" />
          </b-field>
          <b-field expanded :label="$t('ticket.labels.unit')">
            <SelectUnit
              data-test="select-unit"
              :model-value="unit_selected"
              :project_id="q_project_id"
              :project_required="true"
              :units="units"
              @changeSelect="unit => { q_unit_id = $_.get(unit, 'id') }"
              @onSearch="autocompleteUnits"/>
          </b-field>
          <b-field expanded :label="$t('decision.labels.decisions')">
            <SelectDecisionType
              :model-value="decision_type_selected"
              :project_id="q_project_id"
              :unit_id="q_unit_id"
              @changeSelect="dt => { q_decision_type_id = $_.get(dt, 'id') }"/>
          </b-field>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
import ToggleToken from '@/app/shared_components/buttons/ToggleToken.vue';
import FilterTag from '@/app/shared_components/tags/FilterTag.vue';
import SelectProject from '@/app/shared_components/selects/SelectProject.vue';
import SelectUnit from '@/app/shared_components/selects/SelectUnit.vue';
import { mapGetters, mapActions } from 'vuex';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import SelectDecisionType from '@/app/shared_components/selects/SelectDecisionType.vue';
import _ from 'lodash-es';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'NotificationsFilter',
  components: {
    SelectDecisionType,
    ToggleToken,
    SelectProject,
    SelectUnit,
    FilterTag,
  },

  props: {
    sort_order: { type: String, required: true },
    sort_order_type: { type: String, required: true },
  },

  emits: ['set-sort-order', 'fetch-notification-subgroups', 'open-notification-subgroups-all'],

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      q_is_unread_only: false,
      q_project_id: false,
      q_unit_id: false,
      q_decision_type_id: false,
      toggle_filter: false,
      PROFILE_TYPES,
      units: {},
      projects: null,
    };
  },

  computed: {
    ...mapGetters([
      'current_profile',
      'decision_types',
      'sidebar_prefs',
    ]),

    filters() {
      const decision_type_id = this.q_decision_type_id;
      const project_id = this.q_project_id;
      const unread = this.q_is_unread_only;
      const unit_id = this.q_unit_id;
      return {
        decision_type_id, project_id, unit_id, unread,
      };
    },

    project_selected() {
      if (!this.q_project_id) return null;
      return _.find(this.projects, ['id', this.q_project_id]);
    },

    unit_selected() {
      if (!this.q_unit_id) return null;
      return _.find(this.units, ['id', this.q_unit_id]);
    },

    decision_type_selected() {
      if (!this.q_decision_type_id) return null;
      return _.find(this.decision_types, ['id', this.q_decision_type_id]);
    },

    sorting_label() {
      return [this.sort_order_type, this.sort_order].join('_');
    },
  },

  watch: {
    filters: {
      handler() {
        this.UPDATE_SIDEBAR_PREFS(this.filters);
        this.$emit('fetchNotificationSubgroups');
      },
    },
    unit_selected: {
      handler() {
        if (!this.unit_selected && this.q_unit_id) {
          this.q_unit_id = undefined;
        }
      },
    },
  },

  async created() {
    this.q_project_id = this.sidebar_prefs.project_id;
    this.q_decision_type_id = this.sidebar_prefs.decision_type_id;
    this.q_is_unread_only = this.sidebar_prefs.unopen;
    this.q_unit_id = this.sidebar_prefs.unit_id;

    if (this.q_decision_type_id) await this.FETCH_DECISION_TYPE({ id: this.q_decision_type_id });
    if (this.q_unit_id) this.autocompleteUnits();

    if (this.q_project_id) this.autocompleteProjects();
  },

  methods: {
    ...mapActions([
      'FETCH_DECISION_TYPE',
      'UPDATE_SIDEBAR_PREFS',
      'AUTOCOMPLETE_UNITS',
      'AUTOCOMPLETE_PROJECTS',
    ]),

    autocompleteUnits: _.debounce(async function autocompleteUnits(search = '') {
      this.units = _.keyBy(
        await this.AUTOCOMPLETE_UNITS({
          name_or_customer_group_name_cont: search,
          project_id: this.q_project_id,
        }), 'id',
      );
    }, 250),

    autocompleteProjects: _.debounce(async function autocompleteProjects(search = '') {
      this.projects = _.keyBy(
        await this.AUTOCOMPLETE_PROJECTS({
          name_cont: search
        }), 'id');
    }, 250),
  },
};
</script>
