import { ROUTES_PARTNERS } from '@/app/data/route_constants';
import routesDecision from '@/router/partner/routes-decision';
import routesIssueListType from '@/router/partner/routes-issue-list-type';
import routesIssueList from '@/router/partner/routes-issue-list';

const partnerRoutes = [
  {
    name: ROUTES_PARTNERS.CLIENT_PROJECTS,
    path: 'projects',
    component: () => import('@/app/_partners/pages/ProjectsPage.vue'),
  },
  {
    name: ROUTES_PARTNERS.PROJECT,
    path: 'project/:project_id/',
    component: () => import('@/app/shared_components/Project.vue'),
    props: true,
    children: [
      {
        name: ROUTES_PARTNERS.PROJECT_DECISION_TYPES,
        path: 'decisions_types',
        component: () => import('@/app/_partners/pages/DecisionTypesPage.vue'),
        props: true,
      },
      {
        name: ROUTES_PARTNERS.PROJECT_DECISIONS,
        path: 'decision_type/:decision_type_id',
        component: () => import('@/app/_partners/pages/DecisionsPage.vue'),
        props: true,
      },
      {
        path: 'decision_type',
        redirect: { name: ROUTES_PARTNERS.PROJECT_DECISION_TYPES },
      },
      {
        path: 'decision/:decision_id/',
        component: () => import('@/app/_partners/pages/DecisionPage.vue'),
        props: true,
        children: routesDecision,
      },
      {
        path: 'decision',
        redirect: { name: ROUTES_PARTNERS.PROJECT_DECISION_TYPES },
      },
      {
        name: ROUTES_PARTNERS.PROJECT_DOCUMENTS,
        path: 'documents/:level_type?/:level_id?',
        component: () => import('@/app/_partners/pages/DocumentsPage.vue'),
      },
      {
        name: ROUTES_PARTNERS.PROJECT_DOCUMENTS_UNIT,
        path: 'documents/:level_type?/:level_id?',
        component: () => import('@/app/_partners/pages/UnitDocumentsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPES,
        path: 'issue_list_types',
        component: () => import('@/app/_partners/pages/IssueListTypesPage.vue'),
        props: true,
      },
      {
        path: 'issue_list_types/:issue_list_type_id/',
        component: () => import('@/app/_partners/pages/IssueListTypePage.vue'),
        children: routesIssueListType,
        props: true,
      },
      {
        path: 'issue_list_types/:issue_list_type_id/issue_list/:issue_list_id',
        component: () => import('@/app/_partners/pages/IssueListPage.vue'),
        children: routesIssueList,
        props: true,
      },
    ],
  },
  {
    name: ROUTES_PARTNERS.SETTINGS_PROFILE,
    path: 'settings/profile',
    component: () => import('@/app/_partners/pages/SettingsPartnerPage.vue'),
  },
];

export default partnerRoutes;
