<template>
  <aside
    v-if="!$_.isNil(current_project)"
    id="sidenavbar_project"
    data-test="navbar-customer-desktop-sidenavbar"
    style="overflow: hidden;">
    <div
      class="section sidenavbar_up">
      <div class="flex-center">
        <div style="background: white; border-radius: 50%;">
          <avatar
            :format="current_tenant.attributes.picture_logo_format"
            size="90"
            :src="current_tenant.attributes.picture_logo_url_s"
            :username="current_tenant.attributes.name"/>
        </div>
      </div>
      <br>
      <div v-if="$_.values(projects).length > 1" class="select">
        <b-field>
          <b-select
            :modelValue="current_project.id"
            rounded
            @update:modelValue="p_id => initializeProject(p_id)">
            <option
              v-for="project in projects"
              :key="project.id"
              :value="project.id">
              {{ project.attributes.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div v-else class="title is-4 ziggu-navbar-item" style="padding: 15px 5px 0px 5px;">
        {{ current_project.attributes.name }}
      </div>
    </div>
    <div class="sidenavbar_down" style="overflow: hidden;">
      <ul style="overflow-y: auto;">
        <NavbarItem
          v-for="(item, idx) in routesActive"
          :key="idx"
          :item="item"
          :params="{ project_id: current_project.id }"/>
      </ul>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import { NAVBAR_ITEMS_CUSTOMER } from '@/app/data/navbar_constants';
import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';
import NavbarItem from '@/app/shared_components/navbar/NavbarItem.vue';

export default {
  name: 'NavbarDesktop',
  components: { NavbarItem },

  data() {
    return {
      NAVBAR_ITEMS_CUSTOMER,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_project',
      'current_tenant',
      'projects',
    ]),

    routesActive() { return _.filter(NAVBAR_ITEMS_CUSTOMER, this.isRouteActive); },
  },

  methods: {
    initializeProject(project_id) {
      this.$router.push({
        name: ROUTES_CUSTOMERS.DASHBOARD,
        params: { project_id, client_id: this.current_client.id },
      });
    },

    isRouteActive(route) {
      if (!route.feature_toggle) return true;
      return this.current_project.attributes[route.feature_toggle];
    },
  },
};
</script>
