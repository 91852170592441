<template>
  <aside id="sidenavbar_tenant">
    <div class="sidenavbar_up section">
      <div class="flex-center">
        <img src="@/assets/images/ziggu_logo_supervisor.png" style="height: 95px;">
      </div>
    </div>
    <div class="sidenavbar_down">
      <ul>
        <NavbarItem
          v-for="(item, idx) in NAVBAR_ITEMS_SUPERVISORS"
          :key="idx"
          :item="item"/>
      </ul>
      <div class="flex-center title is-5 has-text-white" style="height: 80px;">
        <a href="https://ziggu.io" target="_blank">
          <img src="@/assets/images/ziggu_embossed_black.svg"
               style="opacity: 0.4;"></a>
      </div>
    </div>
  </aside>
</template>

<script>
import NavbarItem from '@/app/shared_components/navbar/NavbarItem.vue';
import { NAVBAR_ITEMS_SUPERVISORS } from '@/app/data/navbar_constants';

export default {
  name: 'NavbarDesktopSupervisor',
  components: {
    NavbarItem,
  },

  data() {
    return {
      NAVBAR_ITEMS_SUPERVISORS,
    };
  },
};
</script>
