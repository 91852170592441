<template>
  <component
    :is="is"
    class="button has-text-weight-semibold"
    :class="[size, is_color, is_outlined ? 'is-primary is-outlined' : 'is-secondary']"
    :disabled="is_disabled || null"
    :to="to"
    @click="submit">
    <div v-if="iconFontawesome" class="icon">
      <FontAwesomeIcon :icon="iconFontawesome"/>
    </div>
    <div v-if="$slots.default" class="has-text-weight-semibold">
      <slot/>
    </div>
  </component>
</template>

<script>

const actions_to_fontawesome_icon = Object.freeze({
  arrow_down: 'arrow-down',
  arrow_up: 'arrow-up',
  create: 'plus',
  download: 'download',
  edit: 'edit',
  preview: ['far', 'eye'],
  save: 'save',
  unpublish: ['far', 'eye-slash'],
  upload: 'upload',
  unlock: 'unlock',
  share: 'share',
});

export default {
  name: 'ButtonSecondary',
  props: {
    is_disabled: { type: Boolean },
    action: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(actions_to_fontawesome_icon).includes(prop),
    },
    to: { type: Object, default: null },
    is_upload: { type: Boolean },
    is_outlined: { type: Boolean, default: true },
    size: { type: String, default: 'is-normal' },
    is_color: { type: String, default: 'is-primary' },
  },

  emits: ['on-click'],

  data() {
    return {
      iconFontawesome: null,
    };
  },

  computed: {
    is() {
      if (this.is_disabled) return 'span';
      if (this.to) return 'router-link';
      if (this.is_upload) return 'a';
      return 'button';
    },
  },

  watch: {
    action: {
      immediate: true,
      handler() {
        this.iconFontawesome = actions_to_fontawesome_icon[this.action];
      },
    },
  },

  methods: {
    submit() {
      if (this.is_disabled) return;
      this.$emit('onClick');
    },
  },
};
</script>
