<template>
  <nav
    id="topbar"
    class="level px-5 py-2 is-marginless topbar"
    data-test="navbar-desktop-topbar">
    <div class="level-left">
      <breadcrumbs class="level-item"/>
    </div>
    <div class="level-right">
      <router-link
        v-if="current_profile.type === PROFILE_TYPES.EMPLOYEE"
        :to="{ name: ROUTES_EMPLOYEES.CLIENT_MARKETPLACE }">
        <a
          class="topbar-item"
          :class="{ 'has-background-primary': is_marketplace_active }"
          data-test="marketplace"
          style="cursor: pointer;">
          <span
            class="icon is-large" :class=" (mq.isMobile || mq.isTablet) ? 'has-text-white' : 'has-text-grey'">
            <span
              class="badge has-badge-rounded"
              :class="is_marketplace_active ? 'has-text-white' : 'has-text-grey'">
              <FontAwesomeIcon :icon="['far', 'store']" size="lg"/>
            </span>
          </span>
        </a>
      </router-link>
      <HelpSidebar/>
      <ButtonTertiary v-if="has_multiple_profiles" action="company" @onClick="selectTenant">
        {{ $t('navbar.topnavbar.selectTeam') }}
      </ButtonTertiary>
      <NotificationSidebar v-if="current_profile.type !== PROFILE_TYPES.SUPERVISOR"/>
      <div class="topbar-divider"/>
      <ProfileDropdown #reference/>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { THEMES } from '@/app/data/themes_constants';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import HelpSidebar from '@/app/shared_components/navbar/TheHelpSidebar.vue';
import NotificationSidebar from '@/app/shared_components/navbar/notifications/TheNotificationsSidebar.vue';
import SelectTenant from '@/app/shared_components/SelectTenant.vue';
import ProfileDropdown from './TheProfileDropdown.vue';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'TopbarDesktop',
  components: {
    HelpSidebar,
    NotificationSidebar,
    ProfileDropdown,
  },

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      PROFILE_TYPES,
      THEMES,
      ROUTES_EMPLOYEES,
    };
  },

  computed: {
    ...mapGetters(['current_profile', 'current_profile_true', 'current_is_impersonation']),
    has_multiple_profiles() {
      if (this.current_is_impersonation && this.current_profile_true.type === PROFILE_TYPES.EMPLOYEE) return false;
      return this.current_profile.attributes.profiles_count > 1;
    },
    is_marketplace_active() {
      return this.$route.name.startsWith(ROUTES_EMPLOYEES.CLIENT_MARKETPLACE);
    },
  },

  methods: {
    selectTenant() {
      this.$buefy.modal.open({

        component: SelectTenant,
      });
    },
  },
};
</script>
