<template>
  <div v-if="initialized" id="app"
       :class="{
         'environment-staging-border': environment === VITE_ENV_STAGING,
         'environment-development-border': environment === VITE_ENV_DEVELOPMENT,
         'environment-review-border': environment === VITE_ENV_REVIEW,
         'ziggu_sm': mq.isMobile || mq.isTablet, 'ziggu_lg': mq.isDesktop
       }">
    <InvoiceOverdueBanner v-if="billing_current_client && billing_current_client.attributes.invoice_overdue"
                          :billing="billing_current_client"/>
    <OutOfOfficeBanner
      v-if="current_client.attributes.ooo"
      :client="current_client"
      :profile="current_profile"/>
    <NavbarTablet v-if="(mq.isMobile || mq.isTablet)" :project_id="project_id"/>
    <NavbarDesktopClient v-if="(mq.isDesktop) && !project_id"/>
    <NavbarDesktopProject v-if="(mq.isDesktop) && project_id" />
    <TopbarDesktop v-if="mq.isDesktop"/>
    <TopbarTablet v-else/>
    <main id="page" :class="{'sidebar-padding': mq.isDesktopWide && sidebar_type }">
      <div class="section container is-widescreen">
        <router-view/>
      </div>
    </main>
  </div>
  <b-loading v-else :is-full-page="true" :model-value="!initialized"/>
</template>

<script>
import NavbarDesktopClient from '@/app/_employees/components/navbar/TheNavbarDesktopClient.vue';
import NavbarDesktopProject from '@/app/_employees/components/navbar/TheNavbarDesktopProject.vue';
import NavbarTablet from '@/app/_employees/components/navbar/TheNavbarTablet.vue';
import TopbarDesktop from '@/app/shared_components/navbar/TheTopbarDesktop.vue';
import TopbarTablet from '@/app/shared_components/navbar/TheTopbarTablet.vue';
import OutOfOfficeBanner from '@/app/shared_components/banner/TheOutOfOfficeBanner.vue';
import InvoiceOverdueBanner from '@/app/_employees/components/InvoiceOverdueBanner.vue';
import profile_mixin from '@/app/util/profile_mixin';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'Employee',
  components: {
    InvoiceOverdueBanner,
    NavbarDesktopClient,
    NavbarDesktopProject,
    NavbarTablet,
    OutOfOfficeBanner,
    TopbarDesktop,
    TopbarTablet,
  },
  mixins: [profile_mixin],

  setup() {
    const mq = useMqService();
    return { mq };
  },

  async created() {
    await this.initProfile();
  },
};
</script>
