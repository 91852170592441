// import
import {
  addDays,
  addMonths,
  addWeeks,
  addMilliseconds,
  differenceInCalendarYears,
  differenceInDays,
  differenceInMilliseconds,
  differenceInSeconds,
  differenceInWeeks,
  isPast,
  isToday,
  isTomorrow,
  isFuture,
  isYesterday,
  parseISO,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import i18n from '@/i18n/index';
import store from '@/store/shared/modules/current';
import { enGB, fr, nl } from 'date-fns/locale';

/**
 * This is our custom wrapper for the Date Fns library.
 *
 * (Custom) functions, time stamp formats, locales are defined here.
 * If you need a function from Date Fns, import it through this wrapper first.
*/

// constants
const locales = { en: enGB, fr, nl };
const DATE_FORMATS = Object.freeze({
  // The prefix 'F' refers to 'Full', meaning the value is written as a full word
  DAY_FMONTH: 'd MMMM', // 31 January
  DAY_FMONTH_YEAR: 'd MMMM yyyy', // 2nd February 2018
  DAY_MONTH_YEAR: 'dd-MM-yyyy', // 02-11-2018
  FDAY_DAY_FMONTH: 'EEEE d MMMM', // Monday 2 December
  FDAY_DAY_FMONTH_YEAR: 'EEEE d MMMM yyyy', // Monday 2 December 2018
  FMONTH_DAY_YEAR: 'MMMM d, yyyy', // January 31, 2018
  FMONTH_YEAR: 'MMMM yyyy', // January 2017
  FULL_DATE: 'do MMMM yyyy, HH:mm', // 2nd January 2018, 24:12
  TIME: 'HH:mm', // '23:12'
  YEAR_MONTH_DAY: 'yyyy-MM-dd', // 2018-11-01
  YEAR_MONTH_DAY_TIME: 'P, HH:mm', // 2018-11-01, 24:12
});

// functions
function parseStringToISO(value) {
  return typeof value === 'string' || value instanceof String ? parseISO(value) : value;
}

function formatWithLocale(date, formatStr, { timezone_adjusted = false, show_time = true } = {}) {
  if (!date || date === 'Invalid Date') return null;
  if (typeof date === 'string') date = new Date(date); // eslint-disable-line
  const { timezone: timeZone = 'Europe/Brussels' } = store.state.current_profile.attributes;
  const zonedDate = timezone_adjusted ? utcToZonedTime(date, timeZone) : date;
  if (!show_time && isToday(zonedDate)) return i18n.global.t('timestamp.labels.today');
  if (!show_time && isTomorrow(zonedDate)) return i18n.global.t('timestamp.labels.tomorrow');
  if (!show_time && isYesterday(zonedDate)) return i18n.global.t('timestamp.labels.yesterday');
  return format(zonedDate, formatStr, { locale: locales[i18n.global.locale], timeZone });
}

function addMillisecondsISO(date, amount) {
  return addMilliseconds(parseStringToISO(date), amount);
}

function isPastISO(date) {
  return isPast(parseStringToISO(date));
}

function distanceInWordsToNowWithLocale(date_utc = null) {
  if (!date_utc) return null;
  if (date_utc && date_utc !== 'Invalid Date') {
    const now = new Date();
    const then = new Date(date_utc);
    const difference_seconds_to_now = differenceInSeconds(now, then);
    const difference_years_to_now = differenceInCalendarYears(now, then);

    // within the minute
    if (difference_seconds_to_now <= 60 && difference_seconds_to_now >= -60) {
      return i18n.global.t('timestamp.labels.justNow');
    }
    // between 1 minute and 1 day before
    if (difference_seconds_to_now > 60 && difference_seconds_to_now <= 86400) {
      return formatWithLocale(date_utc, DATE_FORMATS.DAY_FMONTH);
    }
    // between 1 minute and 1 day later
    if (difference_seconds_to_now < -60 && difference_seconds_to_now >= -86400) {
      return `${i18n.global.t('timestamp.labels.in',
        { time: formatWithLocale(date_utc, DATE_FORMATS.TIME) })}`;
    }
    // yesterday
    if (difference_seconds_to_now > 86400 && difference_seconds_to_now <= 172800) {
      return formatWithLocale(date_utc, DATE_FORMATS.DAY_FMONTH);
    }
    // tomorrow
    if (difference_seconds_to_now < -86400 && difference_seconds_to_now >= -172800) {
      return formatWithLocale(date_utc, DATE_FORMATS.DAY_FMONTH);
    }
    // between yesterday and a year before
    if (difference_seconds_to_now < -172800 && difference_years_to_now > -1) {
      return formatWithLocale(date_utc, DATE_FORMATS.DAY_FMONTH);
    }
    // between tomorrow and a year later
    if (difference_seconds_to_now > 172800 && difference_years_to_now < 1) {
      return formatWithLocale(date_utc, DATE_FORMATS.DAY_FMONTH);
    }
    // over a year
    if (difference_years_to_now >= 1 || difference_years_to_now <= -1) {
      return formatWithLocale(date_utc, DATE_FORMATS.FMONTH_DAY_YEAR);
    }
    return null;
  }
  return null;
}

function getFullDate(date_utc = null) {
  return formatWithLocale(date_utc, DATE_FORMATS.FULL_DATE);
}

function getDateWithTime(date_utc = null) {
  return formatWithLocale(date_utc, DATE_FORMATS.YEAR_MONTH_DAY_TIME);
}

function getRecency(date_utc) {
  if (date_utc && date_utc !== 'Invalid Date') {
    const now = new Date();
    const then = new Date(date_utc);
    const difference_weeks_to_now = differenceInWeeks(now, then);

    switch (true) {
    case difference_weeks_to_now < 1:
      return `${differenceInDays(now, then)}_days`;
    case difference_weeks_to_now >= 1 && difference_weeks_to_now < 4:
      return `${difference_weeks_to_now}_weeks`;
    case difference_weeks_to_now >= 4 && difference_weeks_to_now < 52:
      return `${Math.floor(difference_weeks_to_now / 4)}_months`;
    case difference_weeks_to_now >= 52:
      return `${Math.floor(difference_weeks_to_now / 52)}_years`;
    default:
      return null;
    }
  }
  return null;
}

// export
export {
  addDays,
  addMonths,
  addWeeks,
  addMillisecondsISO,
  DATE_FORMATS,
  differenceInDays,
  differenceInMilliseconds,
  distanceInWordsToNowWithLocale,
  format,
  formatWithLocale,
  getFullDate,
  getDateWithTime,
  getRecency,
  isPastISO,
  isFuture,
  subDays,
  subMonths,
  subWeeks,
};
