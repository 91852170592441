<template>
  <div :class="[is_small || is_medium || is_flexible_width ? 'flex-center' : {}]">
    <div
      class="box is-paddingless"
      :class="{ 'is-clipped': !can_overflow }"
      data-test="modal"
      :style="mq.isMobile ? '' : styling_modal"
    >
      <section
        class="has-text-grey is-paddingless is-flex"
        :class="color"
        style="flex-direction: column; height: 100%">
        <div v-if="is_show_header"
             class="p-5 mt-5">
          <div class="title is-4">
            <slot name="title">
              {{ $t('modal.labels.areYouSure') }}
            </slot>
          </div>
          <div v-if="$slots.subtitle"
               class="subtitle is-5">
            <slot name="subtitle">
              {{ $t('modal.labels.areYouSure') }}
            </slot>
          </div>
          <div v-if="$slots.message"
               class="columns pt-5">
            <div class="column">
              <slot name="message" />
              <div v-if="$slots.sub_message"
                   class="has-text-weight-bold">
                <br><slot name="sub_message" />
              </div>
            </div>
          </div>
          <div v-if="$slots.notification"
               class="notification has-text-weight-medium">
            <slot name="notification" />
          </div>
          <div
            v-if="$slots.notification_danger"
            class="notification is-danger has-text-weight-medium"
            data-test="notification-danger">
            <slot name="notification_danger" />
          </div>
        </div>
        <div
          v-if="$slots.content"
          class="section"
          style="flex: 1; overflow-y: auto;">
          <slot name="content"/>
        </div>
        <footer
          v-if="is_show_footer"
          class="has-background-light mt-5 p-4">
          <div class="buttons">
            <ButtonPrimary
              v-if="$slots.ButtonPrimary && is_show_buttons"
              :action="action"
              data-test="modal-confirm-primary"
              :is_disabled="!is_confirmed"
              @onClick="onSubmit">
              <slot name="ButtonPrimary"/>
            </ButtonPrimary>
            <ButtonPrimary
              v-if="$slots.ButtonLoading && is_show_buttons"
              is_disabled
              is_loading
            >
              <slot name="ButtonLoading">
                {{ $t('button.actions.loading') }}
              </slot>
            </ButtonPrimary>
            <ButtonDestructive
              v-if="$slots.ButtonDestructive && is_show_buttons"
              data-test="modal-confirm-primary"
              :is_disabled="!is_confirmed"
              @onClick="onSubmit">
              <slot name="ButtonDestructive">
                hallo {{ !is_confirmed }}
                {{ $t('button.actions.delete') }}
              </slot>
            </ButtonDestructive>
            <ButtonDestructive
              v-if="$slots.ButtonDestructiveLoading && is_show_buttons"
              data-test="modal-confirm-primary"
              is_disabled
              is_loading
              @onClick="onSubmit">
              <slot name="ButtonDestructive">
                {{ $t('button.actions.loading') }}
              </slot>
            </ButtonDestructive>
            <ButtonSecondary
              v-if="$slots.ButtonSecondary && is_show_buttons"
              data-test="modal-confirm-secondary"
              @onClick="$emit('actionSecondary')">
              <slot name="ButtonSecondary"/>
            </ButtonSecondary>
            <ButtonTertiary
              v-if="is_cancel && is_show_buttons"
              data-test="modal-confirm-tertiary"
              @onClick="onCancel"/>
            <div v-else
                 class="is-flex" style="flex-grow: 1; justify-content: flex-end;">
              <slot name="content-footer"/>
            </div>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    action: { type: String, default: null },
    has_parent: { type: Boolean, default: true },
    is_cancel: { type: Boolean, default: true },
    can_overflow: { type: Boolean },
    is_confirmed: { type: Boolean, default: true },
    is_fixed_height: { type: Boolean },
    is_flexible_width: { type: Boolean },
    is_medium: { type: Boolean },
    is_show_buttons: { type: Boolean, default: true },
    is_show_footer: { type: Boolean, default: true },
    is_show_header: { type: Boolean, default: true },
    is_small: { type: Boolean, default: true },
    prevent_automatic_close: { type: Boolean },
    prevent_enter_shortcut: { type: Boolean },
  },

  emits: ['action-cancel', 'action-primary', 'action-secondary'],

  computed: {
    color() {
      return (this.$slots.ButtonDestructive || this.$slots.ButtonDestructiveLoading)
        ? 'modal-top-border-danger'
        : 'modal-top-border-primary';
    },
    styling_modal() {
      const styling = {};
      if (this.is_fixed_height) styling.height = 'calc(100vh - 200px)';
      if (this.is_flexible_width) styling.width = '100%';
      if (this.is_small) {
        styling.width = '360px';
      } else if (this.is_medium) {
        styling.width = '600px';
      }
      return styling;
    },
  },

  created() {
    const enterHandler = (e) => {
      if (this.prevent_enter_shortcut || !this.is_confirmed) return;
      if (e.key === 'Enter') {
        this.onSubmit();
      }
    };
    document.addEventListener('keydown', enterHandler);
    this.emitter.$on('hook:unmounted', () => {
      document.removeEventListener('keydown', enterHandler);
    });
  },

  methods: {
    onCancel() {
      this.$emit('actionCancel');
      if (this.has_parent) { this.$parent.$parent.close(); }
    },

    onSubmit() {
      this.$emit('actionPrimary');
      if (this.has_parent && !this.prevent_automatic_close) { this.$parent.$parent.close(); }
    },
  },
};
</script>
