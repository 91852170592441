<template>
  <div>
    <div class="columns">
      <div class="img-shadow"/>
      <div class="column is-4 flex-column">
        <section class="section flex-column-section">
          <div class="has-text-centered">
            <img class="login-logo" :src="pictureLogoUrl">
          </div>
          <div class="section">
            <h1 class="title is-1">
              {{ $t('session.error_notfound.title') }}
            </h1>
            <hr>
            <h2 class="subtitle is-2">
              {{ $t('session.error_notfound.subtitle') }}
            </h2>
            <br>
            <a href="/">
              <div class="button is-large">{{ $t('session.error_notfound.goback') }}</div>
            </a>
          </div>
        </section>
        <section class="section">
          <div class="has-text-centered has-text-grey" style="font-size: 10px;">
            <a href="https://ziggu.io" target="_blank"><img alt="Ziggu Logo" class="has-text-grey" src="https://storage.googleapis.com/ziggu-assets/staticpages/ziggu-black-png.png" style="height:15px; margin-top: 5px"></a>
          </div>
        </section>
      </div>
      <img id="background" class="background-img column is-8" :src="pictureLoginUrl">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pictureLogoDefault from '@/assets/images/engage_logo.png';
import pictureLoginDefault from '@/assets/images/engage_background.jpg';

export default {
  name: 'ErrorNotFound',

  computed: {
    ...mapGetters(['current_client']),

    pictureLogoUrl() {
      return this.current_client
        ? this.current_client.attributes.picture_logo_url_m
        : pictureLogoDefault;
    },

    pictureLoginUrl() {
      return this.current_client
        ? this.current_client.attributes.picture_login_url_l
        : pictureLoginDefault;
    },
  },
};
</script>

<style scoped>
  .background-img {
    height: 100vh;
    padding: 0;
    object-fit: cover;
  }

  .img-shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 33.333333333%;
    right: 0;
    -webkit-box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);
    -moz-box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);
    box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);
  }

  @media (max-width: 769px) {
    .background-img{
      display: none
    }
    .img-shadow {
      display: none
    }
  }
  .button {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 150px;
  }
  .login-logo {
    margin: 0 auto;
    max-height: 250px;
  }
  .columns{
    margin: 0;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .flex-column-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
