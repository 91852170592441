<template>
  <span/>
</template>

<script>
import { NOTIFICATIONS_TO_ROUTES } from '@/app/data/notifications_routes';
import { ROUTE_COMMON } from '@/app/data/route_constants';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NotificationsRedirect',

  computed: {
    ...mapGetters([
      'current_profile',
    ]),
  },

  async mounted() {
    try {
      const notification = await this.FETCH_NOTIFICATION(
        {
          target_id: this.current_profile.id,
          id: this.$route.params.notification_id,
        },
      );
      const notificationRoute = NOTIFICATIONS_TO_ROUTES[notification.attributes.key];
      const { parameters, project_id } = notification.attributes;
      const params = _.pickBy({ ...parameters, project_id });
      this.$router.push({
        name: notificationRoute,
        params,
      });
    } catch (e) {
      // redirect to root on failure and afterwards hard refresh to re-trigger init profile
      this.$router.push({ name: ROUTE_COMMON.ROOT }, () => { window.location.reload(true); });
    }
  },

  methods: {
    ...mapActions([
      'FETCH_NOTIFICATION',
    ]),
  },

};
</script>
