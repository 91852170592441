<template>
  <VPopper>
    <template #tip>
      <div
        class="is-flex is-flex-direction-column p-3 is-align-items-flex-center p-4"
        style="width: 700px;">
        <LiteYouTubeEmbed
          :id="$t(`upsell.${feature}.youtube_id`)"
          ref="youtube"
          title="video"/>
        <div class="px-2 py-1">
          <div class="title is-4 mb-1" v-html="$t(`upsell.${feature}.title`)"/>
          <div class="mb-4 mt-3" v-html="$t(`upsell.${feature}.description`)"/>
          <ButtonPrimary @onClick="openIntercom">
            {{ $t('upsell.contact_us') }}
          </ButtonPrimary>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="button has-text-weight-semibold is-primary is-light">
        <div class="icon">
          <FontAwesomeIcon icon="stars"/>
        </div>
        <span
          v-if="$slots.default"
          class="has-text-weight-semibold">
          <slot/>
        </span>
        <span v-else class="has-text-weight-semibold">
          {{ $t(`upsell.${feature}.cta`) }}
        </span>
      </div>
    </template>
  </VPopper>
</template>

<script>
import { mapGetters } from 'vuex';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed';
import 'vue-lite-youtube-embed/style.css';

export default {
  name: 'ButtonUpsell',
  components: { LiteYouTubeEmbed },
  props: {
    feature: { type: String, required: true },
  },

  emits: ['usell-close'],

  computed: {
    ...mapGetters(['current_profile']),
  },

  methods: {
    openIntercom() {
      const message = this.$t(`upsell.${this.feature}.intercom`, { name: this.current_profile.attributes.name });
      window.Intercom('showNewMessage', message);
      this.$parent.$parent.close();
      this.$emit('usellClose');
    },
  },
};
</script>
