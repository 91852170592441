import { PROFILE_TYPES, RECORD_TYPE } from '@/app/data/model_constants';

export const ROUTE_COMMON = {
  ERROR_INTERNAL_SERVER: 'errorInternalServer',
  ERROR_NOT_FOUND: 'errorNotFound',
  NOT_FOUND: 'notFound',
  NOTIFICATIONS_REDIRECT: 'notificationsRedirect',
  LOGIN_RESET_REDIRECT: 'loginResetRedirect',
  ROOT: 'root',
};
export const ROUTES_COMMON = Object.values(ROUTE_COMMON);

// TODO: fixup naming, ROUTE_%PROFILE% for direct reference, ROUTES_%PROFILE% for collection

// on route add: also add/change titles in corresponding language files %language%_window.json
export const ROUTES_CUSTOMERS = {
  DASHBOARD: 'customersDashboard',
  DECISION: 'customerDecision',
  DECISION_DOCUMENTS: 'customersDecisionDocuments',
  DECISION_PROPOSALS: 'customersDecisionProposals',
  DECISION_REDIRECT: 'customersDecisionRedirect',
  DECISION_TICKET_SHOW: 'customersDecisionTicketsShow',
  DECISION_TICKETS: 'customersDecisionTickets',
  DECISIONS: 'customersDecisions',
  DOCUMENTS: 'customersDocuments', // parent dummy route
  DOCUMENTS_ALL: 'customersDocumentsAll',
  DOCUMENTS_FOLDERS: 'customersDocumentsFolders',
  DOCUMENTS_FOLDER: 'customersDocumentsFolder',
  INSTALLMENT_TERMS: 'customersInstallmentTerms',
  INSPIRATION_LISTS: 'customersInspirationLists',
  INSPIRATION_LISTS_SHOW: 'customersInspirationListsShow',
  ISSUES: 'customersIssues',
  ISSUES_DOCUMENTS: 'customersIssuesDocuments',
  ISSUES_TICKETS: 'customersIssuesTickets',
  PROJECT: 'customerProject',
  PROJECT_INFORMATION: 'customersProjectInformation',
  QUESTION_LISTS: 'customersQuestionLists',
  QUESTION_LISTS_SHOW: 'customersQuestionListsShow',
  SETTINGS_PROFILE: 'customersSettingsProfile',
  ROOT: 'customersRoot',
  ROOT_SETTINGS_PROFILE: 'customersRootSettingsProfile',
  TICKETS: 'customersTickets',
  TICKETS_NEW: 'customersTicketsNew',
  TICKETS_SHOW: 'customersTicketsShow',
};

// on route add/change: also add/change titles in corresponding language files %language%_window.json
export const ROUTES_EMPLOYEES = {
  CLIENT_CONTACTS: 'employeesClientContacts',
  CLIENT_CONTACTS_TYPE: 'employeesClientContactsType',
  CLIENT_CONTACTS_NEW: 'employeesClientContactsNew',
  CLIENT_DASHBOARD: 'employeesClientDashboard',
  CLIENT_PROJECTS: 'employeesClientProjects',
  CLIENT_PROJECT_NEW: 'employeesClientProjectsNew',
  CLIENT_TICKETS: 'employeesClientTickets',
  CLIENT_TICKETS_NEW: 'employeesClientTicketsNew',
  CLIENT_TICKETS_SHOW: 'employeesClientTicketsShow',
  CLIENT_EMAIL_CONVERSATIONS: 'employeesClientEmailConversations',
  CLIENT_EMAIL_CONVERSATIONS_SHOW: 'employeesClientEmailConversationsShow',
  PROJECT: 'employeesProject',
  PROJECT_ANNOUNCEMENTS: 'employeesProjectAnnouncements',
  PROJECT_CAMERAS: 'employeesProjectCameras',
  PROJECT_DECISION: 'employeesProjectDecision',
  PROJECT_DECISION_ACTIVITY: 'employeesProjectDecisionActivity',
  PROJECT_DECISION_DOCUMENTS: 'employeesProjectDecisionDocuments',
  PROJECT_DECISION_NEW: 'employeesProjectDecisionsNew',
  PROJECT_DECISION_EDIT: 'employeesProjectDecisionsEdit',
  PROJECT_DECISION_PROPOSALS: 'employeesProjectDecisionProposals',
  PROJECT_DECISION_REMINDERS: 'employeesProjectDecisionReminders',
  PROJECT_DECISION_TICKETS: 'employeesProjectDecisionTickets',
  PROJECT_DECISION_TICKETS_SHOW: 'employeesProjectDecisionTicketsShow',
  PROJECT_DECISION_TYPE: 'employeesProjectDecisionType',
  PROJECT_DECISION_TYPE_NEW: 'employeesProjectDecisionTypeNew',
  PROJECT_DECISION_TYPES: 'employeesProjectDecisionTypes',
  PROJECT_DECISION_TYPE_REDIRECT: 'employeesProjectDecisionTypeRedirect',
  PROJECT_DECISIONS: 'employeesProjectDecisions',
  PROJECT_DOCUMENTS: 'employeesProjectDocuments',
  PROJECT_INSTALLMENT_GROUP: 'employeesProjectInstallmentGroup',
  PROJECT_INSTALLMENT_GROUPS: 'employeesProjectInstallmentGroups',
  PROJECT_INSTALLMENT_GROUP_NEW: 'employeesProjectInstallmentGroupNew',
  PROJECT_INSTALLMENT_NEW: 'employeesProjectInstallmentNew',
  PROJECT_INSTALLMENT_GROUPS_MATRIX: 'employeesProjectInstallmentGroupsMatrix',
  PROJECT_INFORMATION_LEVELS: 'employeesProjectInformationPage',
  PROJECT_INFORMATION_LEVELS_ACTIVITY: 'employeesProjectInformationPageActivity',
  PROJECT_INFORMATION_LEVELS_CONTACTS: 'employeesProjectInformationPageContacts',
  PROJECT_INFORMATION_LEVELS_DECISIONS: 'employeesProjectInformationPageDecisions',
  PROJECT_INFORMATION_LEVELS_DOCUMENTS: 'employeesProjectInformationPageDocuments',
  PROJECT_INFORMATION_LEVELS_INFORMATION: 'employeesProjectInformationPageInformation',
  PROJECT_INFORMATION_LEVELS_INSTALLMENTS: 'employeesProjectInformationPageInstallments',
  PROJECT_INFORMATION_LEVELS_AFTERCARE: 'employeesProjectInformationPageAftercare',
  PROJECT_INFORMATION_LEVELS_MILESTONES: 'employeesProjectInformationPageMilestones',
  PROJECT_INFORMATION_LEVELS_SURVEYS: 'employeesProjectInformationPageSurveys',
  PROJECT_INFORMATION_LEVELS_TASKS: 'employeesProjectInformationPageTasks',
  PROJECT_INFORMATION_LEVELS_TICKETS: 'employeesProjectInformationPageTickets',
  PROJECT_INFORMATION_SPACES: 'employeesProjectInformationSpaces',
  PROJECT_ISSUE_INBOX: 'employeesProjectIssueInbox',
  PROJECT_ISSUE_LIST_TYPES: 'employeesProjectIssueListTypes',
  PROJECT_ISSUE_LIST_TYPE_ISSUES: 'employeesProjectIssueListTypeIssues',
  PROJECT_ISSUE_LIST_TYPE_UNITS: 'employeesProjectIssueListTypeUnits',
  PROJECT_ISSUE_LIST_TYPE_NEW: 'employeesProjectIssueListTypeNew',
  PROJECT_ISSUE_LIST: 'employeesProjectIssueList',
  PROJECT_ISSUE_LIST_ISSUES: 'employeesProjectIssueListIssues',
  PROJECT_ISSUE_LISTS_NEW: 'employeesProjectIssueListsNew',
  PROJECT_ISSUE_UNIT_ISSUES: 'employeesProjectIssueListUnitIssues',
  PROJECT_ISSUE_UNIT_DOCUMENTS: 'employeesProjectIssueListUnitDocuments',
  PROJECT_ISSUE_UNIT_TICKETS: 'employeesProjectIssueListUnitTickets',
  PROJECT_EMAIL_CONVERSATIONS: 'employeesProjectEmailConversations',
  PROJECT_EMAIL_CONVERSATIONS_SHOW: 'employeesProjectEmailConversationsShow',
  PROJECT_PROPOSAL_TEMPLATES: 'employeesProjectDecisionProposalTemplate',
  PROJECT_QUESTION_LISTS: 'employeesProjectQuestionLists',
  PROJECT_QUESTION_LISTS_SHOW: 'employeesProjectQuestionListsShow',
  PROJECT_SALES: 'employeesProjectSales',
  PROJECT_SALES_UNITS: 'employeesProjectSalesUnits',
  PROJECT_SALES_DOCUMENTS: 'employeesProjectSalesDocuments',
  PROJECT_SALES_TICKETS: 'employeesProjectSalesTickets',
  PROJECT_SURVEY_NEW: 'employeesProjectSurveyNew',
  PROJECT_SURVEY_TYPE_NEW: 'employeesProjectSurveyTypeNew',
  PROJECT_SURVEY_TYPES: 'employeesProjectSurveyTypes',
  PROJECT_SURVEYS: 'employeesProjectSurveys',
  PROJECT_SURVEY_SUMMARY: 'employeesProjectSurveySummary',
  PROJECT_TICKETS: 'employeesProjectTickets',
  PROJECT_TICKETS_NEW: 'employeesProjectTicketsNew',
  PROJECT_TICKETS_SHOW: 'employeesProjectTicketsShow',
  PROJECT_TODO_LISTS: 'employeesProjectTodoLists',
  ROOT: 'employeesRoot',
  CLIENT: 'employeesClient',
  CLIENT_SETTINGS: 'employeesClientSettings',
  CLIENT_COMPANY: 'employeesClientCompany',
  CLIENT_INTEGRATIONS: 'employeesClientIntegrations',
  SETTINGS_PROFILE: 'employeesSettingsProfile',
  SETTINGS_TEAM: 'employeesSettingsTeam',
  CLIENT_MARKETPLACE: 'employeesMarketplace',
};

// on route add: also add/change titles in corresponding language files %language%_window.json
export const ROUTES_SUPERVISORS = {
  AUDIT_LOG_DATA: 'supervisorsAuditLogData',
  AUDIT_LOG_DATAS_SEARCH: 'supervisorsAuditLogDatasSearch',
  CLIENT: 'supervisorsClient',
  CLIENT_COMPANIES: 'supervisorsClientCompanies',
  CLIENT_CUSTOMERS: 'supervisorsClientCustomers',
  CLIENT_EDIT: 'supervisorsClientEdit',
  CLIENT_EMPLOYEE_NEW: 'supervisorsClientEmployeeNew',
  CLIENT_EMPLOYEES: 'supervisorsClientEmployees',
  CLIENT_FINANCIALS: 'supervisorsClientFinancials',
  CLIENT_INTEGRATION: 'supervisorsClientIntegration',
  CLIENT_INTEGRATIONS: 'supervisorsClientIntegrations',
  CLIENT_LEADS: 'supervisorsClientLeads',
  CLIENT_METRICS: 'supervisorsClientMetrics',
  CLIENT_NEW: 'supervisorsClientNew',
  CLIENT_PARTNERS: 'supervisorsClientPartners',
  CLIENT_PROJECT: 'supervisorsClientProject',
  CLIENT_PROJECT_EDIT: 'supervisorsClientProjectEdit',
  CLIENT_PROJECT_NEW: 'supervisorsClientProjectNew',
  CLIENT_PROJECTS: 'supervisorsClientProjects',
  CLIENTS: 'supervisorsClients',
  DASHBOARD: 'supervisorsDashboard',
  DASHBOARD_HANDBOOK: 'supervisorsDashboardHandbook',
  DASHBOARD_PRODUCT: 'supervisorsDashboardProduct',
  DASHBOARD_ENGINEERING: 'supervisorsDashboardEngineering',
  DASHBOARD_MARKETING: 'supervisorsDashboardMarketing',
  DASHBOARD_SALES: 'supervisorsDashboardSales',
  DASHBOARD_SUCCESS: 'supervisorsDashboardSuccess',
  EMAIL_EVENT: 'supervisorsEmailEvent',
  EMAILS: 'supervisorsEmails',
  IMPERSONATE_REDIRECT: 'supervisorsImpersonateRedirect',
  METRICS: 'supervisorsMetrics',
  ROOT: 'supervisorsRoot',
  USER: 'supervisorsUser',
  USER_AUTH0_LOG: 'supervisorsUserAuth0Log',
  USER_AUTH0_LOGS: 'supervisorsUserAuth0Logs',
  USER_EMAIL_EVENT: 'supervisorsUserEmailEvent',
  USER_EMAIL_EVENTS: 'supervisorsUserEmailEvents',
  USER_INFO: 'supervisorsUserInfo',
  USER_PROFILE: 'supervisorsUserProfile',
  USER_PROFILE_EMAIL_EVENT: 'supervisorsUserProfileEmailEvent',
  USER_PROFILE_EMAIL_EVENTS: 'supervisorsUserProfileEmailEvents',
  USER_PROFILE_INFO: 'supervisorsUserProfileInfo',
  USER_PROFILE_SESSIONS: 'supervisorsUserProfileSessions',
  USER_PROFILE_TENANTS: 'supervisorsUserProfileTenants',
  USER_PROFILES: 'supervisorsUserProfiles',
  USER_SENTRY: 'supervisorsUserSentry',
  USERS_SEARCH: 'supervisorsUsersSearch',
};

export const ROUTES_PARTNERS = {
  CLIENT_DASHBOARD: 'partnersClientDashboard',
  CLIENT_PROJECTS: 'partnersClientProjects',
  PROJECT: 'partnersProject',
  PROJECT_DECISION: 'partnersProjectDecision',
  PROJECT_DECISION_DOCUMENTS: 'partnersProjectDecisionDocuments',
  PROJECT_DECISION_PROPOSALS: 'partnersProjectDecisionProposals',
  PROJECT_DECISION_TICKETS: 'partnersProjectDecisionTickets',
  PROJECT_DECISION_TICKETS_SHOW: 'partnersProjectDecisionTicketsShow',
  PROJECT_DECISION_TYPES: 'partnersProjectDecisionTypesItems',
  PROJECT_DECISIONS: 'partnersProjectDecisions',
  PROJECT_DOCUMENTS: 'partnersProjectDocuments',
  PROJECT_DOCUMENTS_UNIT: 'partnersProjectDocumentsUnit',
  PROJECT_ISSUE_LIST_TYPES: 'partnersProjectIssueListTypes',
  PROJECT_ISSUE_LIST_TYPE_ISSUES: 'partnersProjectIssueListTypeIssues',
  PROJECT_ISSUE_LIST_TYPE_UNITS: 'partnersProjectIssueListTypeUnits',
  PROJECT_ISSUE_LIST: 'partnersProjectIssueList',
  PROJECT_ISSUE_LIST_ISSUES: 'partnersProjectIssueListIssues',
  PROJECT_ISSUE_UNIT_ISSUES: 'partnersProjectIssueListUnitIssues',
  PROJECT_ISSUE_UNIT_DOCUMENTS: 'partnersProjectIssueListUnitDocuments',
  PROJECT_ISSUE_UNIT_TICKETS: 'partnersProjectIssueListUnitTickets',
  ROOT: 'partnersRoot',
  SETTINGS_PROFILE: 'partnersSettingsProfile',
};

export const ROUTES_USERS = {
  DASHBOARD: 'usersDashboard',
  ROOT: 'usersRoot',
};

export const PROFILE_TO_ROUTES = {
  [PROFILE_TYPES.CUSTOMER]: Object.values(ROUTES_CUSTOMERS),
  [PROFILE_TYPES.EMPLOYEE]: Object.values(ROUTES_EMPLOYEES),
  [PROFILE_TYPES.PARTNER]: Object.values(ROUTES_PARTNERS),
  [PROFILE_TYPES.SUPERVISOR]: Object.values(ROUTES_SUPERVISORS),
};

export const PROFILE_TO_START_ROUTE = {
  [PROFILE_TYPES.CUSTOMER]: ROUTES_CUSTOMERS.ROOT,
  [PROFILE_TYPES.EMPLOYEE]: ROUTES_EMPLOYEES.CLIENT_DASHBOARD,
  [PROFILE_TYPES.PARTNER]: ROUTES_PARTNERS.CLIENT_PROJECTS,
  [PROFILE_TYPES.SUPERVISOR]: ROUTES_SUPERVISORS.DASHBOARD,
};

export const PROJECT_INFORMATION_TABS = Object.freeze({
  INFORMATION: {
    levels: [RECORD_TYPE.PROJECT, RECORD_TYPE.PHASE, RECORD_TYPE.LOT, RECORD_TYPE.BUILDING, RECORD_TYPE.UNIT],
    tab: 'information',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  },
  CONTACTS: {
    levels: [RECORD_TYPE.PROJECT],
    ft_client_flag: 'ft_dev_sales',
    tab: 'contacts',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_CONTACTS,
  },
  MILESTONES: {
    levels: [RECORD_TYPE.PROJECT],
    ft_flag: 'ft_installments',
    tab: 'milestones',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_MILESTONES,
  },
  TODOS: {
    levels: [RECORD_TYPE.PROJECT, RECORD_TYPE.PHASE, RECORD_TYPE.LOT, RECORD_TYPE.BUILDING, RECORD_TYPE.UNIT],
    tab: 'todos',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_TASKS,
  },
  DOCUMENTS: {
    levels: [RECORD_TYPE.UNIT],
    tab: 'documents',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_DOCUMENTS,
  },
  TICKETS: {
    levels: [RECORD_TYPE.UNIT],
    tab: 'tickets',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_TICKETS,
  },
  DECISIONS: {
    levels: [RECORD_TYPE.UNIT],
    tab: 'decisions',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_DECISIONS,
  },
  INSTALLMENTS: {
    levels: [RECORD_TYPE.UNIT],
    ft_flag: 'ft_installments',
    tab: 'installment',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INSTALLMENTS,
  },
  AFTERCARE: {
    levels: [RECORD_TYPE.UNIT],
    ft_flag: 'ft_aftercare',
    tab: 'aftercare',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_AFTERCARE,
  },
  SURVEYS: {
    levels: [RECORD_TYPE.UNIT],
    tab: 'surveys',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_SURVEYS,
  },
  ACTIVITY: {
    levels: [RECORD_TYPE.PROJECT, RECORD_TYPE.UNIT],
    tab: 'activity',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_ACTIVITY,
  },
});
