import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';
import routesDecision from '@/router/customer/routes-decision';
import Customer from '@/app/_customers/Customer.vue';
import routesIssues from '@/router/customer/routes-issues';

// Pay attention: when changing routes and params, be extra careful and check with backend notifiable params
// Route params and notifiable params for matched for notification routing

const customerRoutes = [
  // non-project namespaced routes
  {
    // entry route for email footer link click to adjust email/notification preferences
    name: ROUTES_CUSTOMERS.ROOT_SETTINGS_PROFILE, // redirects to corresponding project route, SETTINGS_PROFILE
    path: 'settings/profile',
    component: Customer,
    props: true,
  },
  // project namespaced routes
  {
    path: 'project/:project_id',
    component: () => import('@/app/_customers/Project.vue'),
    props: true,
    children: [
      {
        name: ROUTES_CUSTOMERS.DASHBOARD,
        path: 'dashboard',
        component: () => import('@/app/_customers/pages/DashboardPage.vue'),
        props: true,
      },
      {
        path: 'decision/:decision_id',
        component: () => import('@/app/_customers/pages/DecisionPage.vue'),
        children: routesDecision,
        props: true,
      },
      {
        path: 'decision',
        redirect: { name: ROUTES_CUSTOMERS.DECISIONS },
      },
      {
        name: ROUTES_CUSTOMERS.DECISIONS,
        path: 'decisions',
        component: () => import('@/app/_customers/pages/DecisionsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
        path: 'installment_terms',
        component: () => import('@/app/_customers/pages/InstallmentGroupsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.ISSUES,
        path: 'issues',
        component: () => import('@/app/_customers/pages/AftercarePage.vue'),
        children: routesIssues,
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.DOCUMENTS,
        path: 'documents',
        component: () => import('@/app/_customers/pages/DocumentsPage.vue'),
        children: [
          {
            name: ROUTES_CUSTOMERS.DOCUMENTS_ALL,
            path: 'all',
            component: () => import('@/app/_customers/pages/DocumentsAllPage.vue'),
            props: true,
          },
          {
            name: ROUTES_CUSTOMERS.DOCUMENTS_FOLDERS,
            path: 'categories',
            component: () => import('@/app/_customers/pages/DocumentsFoldersPage.vue'),
            props: true,
          },
          {
            name: ROUTES_CUSTOMERS.DOCUMENTS_FOLDER,
            path: 'category',
            component: () => import('@/app/_customers/pages/DocumentsFolderPage.vue'),
            props: (route) => ({
              attachment_category_id: route.query.attachment_category_id,
              attachable_type: route.query.attachable_type,
              category: route.query.category,
              unit_id: route.query.unit_id,
            }),
          },
        ],
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.INSPIRATION_LISTS,
        path: 'inspiration_lists',
        component: () => import('@/app/_customers/pages/InspirationListsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.INSPIRATION_LISTS_SHOW,
        path: 'inspiration_list/:inspiration_list_id/inspirations',
        component: () => import('@/app/_customers/pages/InspirationListPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.QUESTION_LISTS,
        path: 'question_lists',
        component: () => import('@/app/_customers/pages/QuestionListsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.QUESTION_LISTS_SHOW,
        path: 'question_list/:question_list_id',
        component: () => import('@/app/_customers/pages/QuestionListPage.vue'),
        props: true,
      },
      {
        path: 'question_list',
        redirect: { name: ROUTES_CUSTOMERS.QUESTION_LISTS },
      },
      {
        name: ROUTES_CUSTOMERS.TICKETS,
        path: 'tickets',
        component: () => import('@/app/_customers/pages/TicketsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.TICKETS_NEW,
        path: 'tickets/new',
        component: () => import('@/app/_customers/pages/TicketNewPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.TICKETS_SHOW,
        path: 'ticket/:ticket_id',
        component: () => import('@/app/_customers/pages/TicketPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.PROJECT_INFORMATION,
        path: 'information',
        component: () => import('@/app/_customers/pages/ProjectInformationPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.SETTINGS_PROFILE,
        path: 'settings/profile',
        component: () => import('@/app/_customers/pages/SettingsCustomerPage.vue'),
        props: true,
      },
      {
        name: ROUTES_CUSTOMERS.PROJECT,
        path: '',
        redirect: { name: ROUTES_CUSTOMERS.DASHBOARD },
      },
    ],
  },
];

export default customerRoutes;
