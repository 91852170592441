<template>
  <aside v-if="!$_.isNil(current_project)" id="sidenavbar_project"
         data-test="navbar-employee-desktop-project"
         style="overflow: hidden;">
    <div class="sidenavbar_up section">
      <div class="flex-center">
        <div style="background: white; border-radius: 50%;">
          <avatar
            size="90"
            :src="current_project.attributes.picture_login_url_s"
            :username="current_project.attributes.name"/>
        </div>
      </div>
      <br>
      <p class="title is-4 ziggu-navbar-item" style="margin: 20px 0px;">
        {{ current_project.attributes.name }}
      </p>
      <div class="is-flex flex-center" style="flex-direction: row;">
        <router-link :to="{ name: ROUTES_PARTNERS.CLIENT_PROJECTS}">
          <div class="button is-rounded is-white">
            <div class="icon">
              <i class="fas fa-arrow-left"/>
            </div>
            <div>{{ $t('project.labels.projects') }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="sidenavbar_down" style="overflow: hidden;">
      <ul style="overflow-y: auto;">
        <NavbarItem
          v-for="(item, idx) in routesActive"
          :key="idx"
          :item="item"
          :params="{ project_id: current_project.id }"/>
      </ul>
      <div class="flex-center title is-5 has-text-white section">
        <a href="https://ziggu.io" target="_blank">
          <img src="../../../../assets/images/ziggu_embossed_black.svg" style="opacity: 0.4;">
        </a>
      </div>
    </div>
  </aside>
</template>

<script>

import {
  NAVBAR_ITEMS_PARTNERS_PROJECT,
  NAVBAR_ITEMS_PARTNERS_PROJECT_ACTIVE_ROLE_NEEDED,
} from '@/app/data/navbar_constants';
import { ROUTES_PARTNERS } from '@/app/data/route_constants';
import { mapGetters } from 'vuex';
import NavbarItem from '@/app/shared_components/navbar/NavbarItem.vue';

export default {
  name: 'NavbarDesktopProject',
  components: {
    NavbarItem,
  },

  data() {
    return {
      ROUTES_PARTNERS,
    };
  },

  computed: {
    ...mapGetters(['current_project', 'current_profile']),
    routesActive() { return _.filter(NAVBAR_ITEMS_PARTNERS_PROJECT, this.isRouteActive); },
  },

  methods: {
    isRouteActive(route) {
      if (route.feature_toggle && !this.current_project.attributes[route.feature_toggle]) return false;
      if (NAVBAR_ITEMS_PARTNERS_PROJECT_ACTIVE_ROLE_NEEDED.includes(route.name)) {
        return this.current_profile.attributes.active_role_project_ids.includes(parseInt(this.current_project.id, 10));
      }

      return true;
    },
  },
};
</script>
