<template>
  <div v-if="is_helpcenter_available">
    <a class="topbar-item" style="cursor: pointer;" @click="toggleSidebar">
      <span
        class="icon is-large" :class=" (mq.isMobile || mq.isTablet) ? 'has-text-white' : 'has-text-grey'">
        <span
          class="badge has-badge-rounded"
          :class="(mq.isMobile || mq.isTablet) ? 'ziggu-navbar-item' : 'has-text-grey'">
          <i class="far fa-circle-question fa-lg"/>
        </span>
      </span>
    </a>
    <b-sidebar
      id="sidebar-helpcenter"
      class="flex-column"
      :fullheight="true"
      :fullwidth="(mq.isMobile || mq.isTablet)"
      :mobile="(mq.isMobile || mq.isTablet) ? 'mobile' : ''"
      :modelValue="is_visible"
      :right="true"
      type="is-white">
      <section :class="`mt-3 mx-4 ${(mq.isMobile || mq.isTablet) ? 'is-mobile' : ''}`">
        <div class="level is-mobile">
          <div class="level-left" style="flex-direction: column;">
            <TitleSmall>
              {{ $t('navbar.topnavbar.helpcenter') }}
            </TitleSmall>
          </div>
          <div class="level-right" style="flex-direction: column;">
            <div class="level-item">
              <div class="field is-grouped">
                <div
                  class="hover-primary p-2"
                  @click="openFullScreen">
                  <FontAwesomeIcon icon="expand-wide" size="lg"/>
                </div>
                <div
                  class="hover-primary p-2"
                  @click="toggleSidebar">
                  <FontAwesomeIcon icon="arrow-right" size="lg"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <iframe v-if="initialized"
              id="helpcenter"
              class="mb-3"
              :src="url"
              style="width: 100%; height: 100vh; border: 0;"/>
    </b-sidebar>
  </div>
</template>

<script>
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { mapGetters, mapActions } from 'vuex';
import { useMqService } from '@/plugins/mq';

const SIDEBAR_TYPE = 'help';

export default {
  name: 'HelpSidebar',

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      initialized: false, // lazy loading (once) of iframe upon toggle sidebar
      PROFILE_TYPES,
      url: null,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_profile',
      'current_project',
      'current_help_center_base_url',
      'sidebar_type',
    ]),

    is_visible() {
      return this.sidebar_type === SIDEBAR_TYPE;
    },

    is_helpcenter_available() {
      const profile_types_helpcenter = [PROFILE_TYPES.EMPLOYEE, PROFILE_TYPES.SUPERVISOR];
      if (!_.isEmpty(this.current_client)) profile_types_helpcenter.push(PROFILE_TYPES.PARTNER);

      return profile_types_helpcenter.includes(this.current_profile.type);
    },

    url_initial() {
      return `${this.current_help_center_base_url}/index.html`;
    },
  },

  created() {
    this.url = this.url_initial;
    this.$root.emitter.$on('showHelpSidebarPage', this.showHelpSidebarPage);
  },

  methods: {
    ...mapActions([
      'SHOW_SIDEBAR',
    ]),

    toggleSidebar() {
      if (this.sidebar_type === SIDEBAR_TYPE) {
        this.SHOW_SIDEBAR(null);
        this.hide_intercom(false);
      } else {
        this.initialized = true;
        if (!this.mq.isDesktop) this.hide_intercom(true);
        this.SHOW_SIDEBAR(SIDEBAR_TYPE);
        this.setSidebarMarginTop();
        window.addEventListener('resize', _.debounce(() => {
          this.setSidebarMarginTop();
        }, 100));
      }
    },

    hide_intercom(hide) {
      window.Intercom('update', { hide_default_launcher: hide });
    },

    showHelpSidebarPage(url_relative) {
      this.url = `${this.current_help_center_base_url}/${url_relative}`;
      this.toggleSidebar(SIDEBAR_TYPE);
    },

    openFullScreen() {
      let url;
      try {
        // obtaining iframe URL only works in case of same-origin URL (port included!), otherwise DOMException is thrown
        url = document.getElementById('helpcenter').contentWindow.location.href;
      } catch {
        // fallback to help center home (applicable as well in local mode, as port of app and help differ)
        url = this.url_initial;
      }
      window.open(url, '_blank', 'noopener=false');
    },

    setSidebarMarginTop() {
      let margin_top = 0;
      if (document.getElementById('invoice_overdue_banner')) {
        margin_top += document.getElementById('invoice_overdue_banner').offsetHeight;
      }
      if (document.getElementById('ooo_banner')) {
        margin_top += document.getElementById('ooo_banner').offsetHeight;
      }
      if (!this.mq.isMobile) {
        margin_top += document.getElementsByClassName('topbar')[0].offsetHeight;
      }
      const sidebar_content = document.getElementById('sidebar-helpcenter')
        .getElementsByClassName('sidebar-content')[0];
      sidebar_content.style.marginTop = `${margin_top}px`;
      sidebar_content.style.height = `${window.innerHeight - margin_top}px`;
    },
  },
};
</script>
